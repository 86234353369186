import { useState } from 'react';
import { ArrowPathIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const SearchBarWithDropdown = ({
  filterOptions = ['All', 'Active', 'Job Site', 'PM'],
  defaultFilter = 'All',
  placeholder = 'Enter a search term',
  onSearch,
  onTyping,
  onReset,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState(defaultFilter);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // read from localStorage or fall back to []
  const [searchHistory, setSearchHistory] = useState(() => {
    const saved = localStorage.getItem('searchHistory');
    return saved ? JSON.parse(saved) : [];
  });

  const handleSearchChange = (e) => {
    const newTerm = e.target.value;
    setSearchTerm(newTerm);
    onTyping?.(newTerm, filterType);
  };

  const handleSearchClick = () => {
    onSearch?.(searchTerm, filterType);

    if (!searchTerm.trim()) return;
    // put newest first, remove old duplicates
    const updated = [
      searchTerm,
      ...searchHistory.filter((term) => term !== searchTerm),
    ].slice(0, 5);

    setSearchHistory(updated);
    localStorage.setItem('searchHistory', JSON.stringify(updated));
  };

  // Press Enter to search
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearchClick();
    }
  };

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleFilterSelect = (option) => {
    setFilterType(option);
    setIsDropdownOpen(false);
    onTyping?.(searchTerm, option);
  };

  const handleResetClick = () => {
    setSearchTerm('');
    setFilterType(defaultFilter);
    setSearchHistory([]); // optional: clear the history too?
    onReset?.();
    localStorage.removeItem('searchHistory'); // optional
  };

  return (
    <div className="space-y-2">    
      <div className="flex items-center space-x-2">
        {/* Text input */}
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={(e) => {
          if (e.key === 'Enter') {
              handleSearchClick();
            }
          else if (e.key === 'Escape') {
              e.preventDefault();
              setSearchTerm('');
              setFilterType(defaultFilter);
              handleResetClick();
           }
          }} 
          placeholder={placeholder}
          className="flex-grow border border-gray-300 rounded-md px-3 py-2"
        />

        {/* Split-button container */}
        <div className="relative inline-flex">
          {/* Left: actual search */}
          <button
            onClick={handleSearchClick}
            className="flex items-center bg-green-600 hover:bg-green-700 text-white px-3 py-2 rounded-l-md"
          >
            Search ({filterType})
          </button>

          {/* Right: dropdown toggle */}
          <button
            onClick={toggleDropdown}
            className="flex items-center bg-green-500 hover:bg-green-700 text-white px-2 rounded-r-md"
          >
            <ChevronDownIcon className="h-4 w-4" />
          </button>

          {/* Dropdown menu */}
          {isDropdownOpen && (
            <div className="absolute right-0 mt-10 w-28 bg-white border border-gray-200 rounded-md shadow-lg z-10">
              {filterOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => handleFilterSelect(option)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Reset button */}
        <button
          type="button"
          onClick={handleResetClick}
          className="flex items-center bg-orange-600 hover:bg-orange-700 text-white px-3 py-2 rounded-md"
        >
          <ArrowPathIcon className="h-5 w-5 mr-1" />
          Reset
        </button>
      </div>

      {/* Recent Searches Section */}
      {searchHistory.length > 0 && (
        <div className="bg-white border rounded p-2 text-sm w-72">
          <h4 className="font-semibold mb-1">Recent Searches</h4>
          <ul>
            {searchHistory.map((term, idx) => (
              <li
                key={idx}
                onClick={() => {
                  setSearchTerm(term);
                  onSearch?.(term, filterType);
                }}
                className="cursor-pointer hover:bg-gray-100 px-2 py-1 rounded"
              >
                {term}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBarWithDropdown;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const TechInspectionFdny = () => {
  const [inspections, setInspections] = useState([]);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // Fetch data from your PHP endpoint on mount
  useEffect(() => {
    fetchInspections();
  }, [apiUrl]);

  const fetchInspections = async () => {
    try {
      const response = await axios.get(`${apiUrl}/fdny_crud.php?getTechInspection=true`);
      setInspections(response.data); // Should be an array of inspection objects
    } catch (error) {
     if (error.response && error.response.status === 401) {
       navigate('/login');
     } 
    }
  };

  const openModal = (inspectionId) => {
    const item = inspections.find((insp) => insp.id === inspectionId);
    setSelectedInspection(item);
  };
  // Close modal
  const closeModal = () => {
    setSelectedInspection(null);
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <h1 className="text-xl font-bold mb-4">Inspections</h1>

      {/* List of inspections */}
      <ul className="space-y-2">
        {inspections.map((item) => (
          <li
            key={item.id}
            className="bg-white rounded shadow p-3"
          >
            <button
              onClick={() => openModal(item.id)}
              className="w-full text-left"
            >
              {/* If not canceled, show normal text, else show strikethrough */}
              {item.status !== 'cancel' ? (
                <div className="flex">
                  {/* Date Block */}
                  <div className="mr-3 text-center">
                    <div className="text-2xl font-bold">{item.day}</div>
                    <div className="text-sm text-gray-500">{item.month}</div>
                  </div>

                  {/* Info Block */}
                  <div>
                    <div className="text-sm text-gray-700">
                      {item.startTime}{' '}
                      {item.tech ? `By: ${item.tech}` : '(No Tech Assigned)'}
                    </div>
                    <div className="font-bold text-gray-900">{item.address}</div>
                    <div className="text-gray-600">{item.tenant}</div>
                  </div>
                </div>
              ) : (
                <div className="flex">
                  {/* Date Block with strike */}
                  <div className="mr-3 text-center">
                    <div className="text-2xl font-bold line-through">
                      {item.day}
                    </div>
                    <div className="text-sm text-gray-500 line-through">
                      {item.month}
                    </div>
                  </div>

                  {/* Info Block with strike */}
                  <div>
                    <div className="text-sm text-gray-400 line-through">
                      {item.startTime}{' '}
                      {item.tech ? `By: ${item.tech}` : '(No Tech Assigned)'}
                    </div>
                    <div className="font-bold text-gray-400 line-through">
                      {item.address}
                    </div>
                    <div className="text-gray-400 line-through">{item.tenant}</div>
                  </div>
                </div>
              )}
            </button>
          </li>
        ))}
      </ul>

      {/* Modal for selected inspection details */}
      {selectedInspection && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md w-11/12 max-w-md">
            <h3 className="text-lg font-bold mb-4">Details</h3>
            {/* Show details as you did in Angular */}
            <p className="mb-1">
              <span className="font-semibold">Status:</span> {selectedInspection.status}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Tenant:</span> {selectedInspection.tenant}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Address:</span> {selectedInspection.address}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Tech:</span> {selectedInspection.tech}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Inspector:</span> {selectedInspection.inspector}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Date:</span>{' '}
              {selectedInspection.month}/{selectedInspection.day} @ {selectedInspection.startTime}
            </p>
            <p className="mb-4">
              <span className="font-semibold">Notes:</span> {selectedInspection.notes}
            </p>

            {/* Close button */}
            <button
              onClick={closeModal}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TechInspectionFdny;

import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import CurrencyFormat from '../components/CurrencyFormat';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import SearchBarCP from '../components/SearchBarCP';
import { CloseButton, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindow from '../components/ModalWindow';
import ModalNotesSection from '../components/ModalNotesSection';
import ProgressCustomBar from '../components/ProgressCustomBar';
import Truncate from '../components/Truncate';
import { useUserData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';

// <-- Import your new modal
import EngineeringModal from '../components/EngineeringModal';

const Engineering = () => {  
  const navigate = useNavigate();
  
  // <-- NEW: Pagination states
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/engineering_crud.php`;
  const [projectMode, setProjectMode] = useState('current');// Current vs. All Projects
  const [originalEngineering, setOriginalEngineering] = useState([]); // Store original data for reverting

  // Our main data array
  const [engineeringList, setEngineeringList] = useState([]);
  // The filtered list (search)
  const [filteredEngineering, setFilteredEngineering] = useState([]);
  
  // compute pagination
  const totalPages = Math.ceil(filteredEngineering.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentContracts = filteredEngineering.slice(startIndex, endIndex);  

  // Controls whether we hide/show items
  const [showApproved, setShowApproved] = useState(true);

  // For notes
  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit_notes',
    user_id: '',
    data: {}
  });
  const [notesIdOpen, setNotesIdOpen] = useState(null);

  // UI states
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  // We integrate the new EngineeringModal
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const userData = useUserData();

  // ---------------------------
  // 1) Fetch data on mount
  // ---------------------------
  useEffect(() => {
    fetchEngineering();
  }, []);

  const fetchEngineering = async () => {
    try {
      const response = await axios.get(`${apiUrl}/engineering_crud.php`, {
        params: { getList: 'list' }
      });
      const data = response.data;
      if (data.error) {
        toast.error(data.error, { position: 'top-center' });
        setEngineeringList([]);
        setFilteredEngineering([]);
      } else if (Array.isArray(data)) {
        setEngineeringList(data);
        setFilteredEngineering(data);
      } else {
        console.error('Unexpected data format:', data);
        setEngineeringList([]);
        setFilteredEngineering([]);
      }
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------
  // 2) Searching
  // ---------------------------
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (!value) {
      setFilteredEngineering(engineeringList);
    } else {
      const filtered = engineeringList.filter(eItem =>
        (eItem.description && eItem.description.toLowerCase().includes(value.toLowerCase())) ||
        (eItem.job_site && eItem.job_site.toLowerCase().includes(value.toLowerCase())) ||
        (eItem.job_number && eItem.job_number.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredEngineering(filtered);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = { status: 'search', data: { search: searchTerm } };
    try {
      const response = await axios.post(`${apiUrl}/engineering_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (Array.isArray(response.data)) {
        setEngineeringList(response.data);
        setFilteredEngineering(response.data);
      } else {
        // Clear or revert
        setEngineeringList([]);
        setFilteredEngineering([]);
        toast.error(`Not found: ${searchTerm}`, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error searching active jobs', error);
    } finally {
      setLoading(false);
    }
  };

  // Toggle showing approved
  const toggleShowApproved = () => {
    setShowApproved(!showApproved);
  };

  // For notes modals
  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  // -----------
  // 3) Sub Menu
  // -----------
  const getLinksForJob = (engineer) => {
    let links = [
      { name: 'Edit Job', click: () => openModalForEdit(engineer) },
      { name: 'Complete', click: handleComplete },
      { name: 'Approve PW', click: handleApproval },
      {
        name: engineer.job_status === 'void' ? 'Remove void' : 'Void project',
        click: () => voidJobSubmit(engineer.id, engineer.job_status)
      }
    ];
    return links;
  };

  // -----------
  // 4) Handle “Edit Job” => open modal
  // -----------
  const openModalForEdit = (engineer) => {
    setEditData(engineer); // pass row’s data to the modal
    setModalOpen(true);
  };

  // -----------
  // 5) The modal’s “Submit” callback
  // -----------
  const handleModalSubmit = async (formData) => {
    
    try {
      const response = await axios.post(`${apiUrl}/engineering_crud.php`, formData, {
        headers: { 'Content-Type': 'application/json' }
      });
      // Suppose the backend returns { success: { ...updatedRow } } or something similar
      if (response.data.success) {
        toast.success('Saved engineering data', { position: 'top-center' });

        // updated row from server
        const updated = response.data.success;

        // If it was “new” vs “edit” you might do different logic
        if (formData.data.id) {
          // Edit existing row: update in local state
          setFilteredEngineering(prev =>
            prev.map(item => item.id === updated.id ? { ...item, ...updated } : item)
          );
          setEngineeringList(prev =>
            prev.map(item => item.id === updated.id ? { ...item, ...updated } : item)
          );
        } else {
          // newly created
          setEngineeringList(prev => [updated, ...prev]);
          setFilteredEngineering(prev => [updated, ...prev]);
        }

        setModalOpen(false);
        setEditData(null);
      } else if (response.data.error) {
        toast.error(response.data.error, { position: 'top-center' });
      } else {
        toast.error('Error saving data', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error saving engineering item:', error);
      toast.error('Error saving data', { position: 'top-center' });
    }
  };

  // -----------
  // 6) Other logic e.g. “Complete”, “Approve PW”, “Void job”
  // -----------
  const handleComplete = (e) => {
    // ...
    toast.info('Complete not implemented yet');
  };

  const handleApproval = (e) => {
    // ...
    toast.info('Approve not implemented yet');
  };

  const voidJobSubmit = async (id, currentStatus) => {
    // ...
    toast.info('Void not implemented yet');
  };

    // Fetch all projects (if user selects “All Projects”)
  const fetchAllProjects = async () => {
    try {
      // Adjust if your backend expects different params for “all projects”
      const response = await axios.get(`${apiUrl}/engineering_crud.php?getList=all`);
      if (Array.isArray(response.data)) {
        setEngineeringList(response.data);
        setFilteredEngineering(response.data);
      } else {
        toast.error('Unexpected data format for all projects', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error fetching all projects', error);
    }
  };

    // Handle the dropdown toggle
  const handleProjectModeChange = async (e) => {
    const mode = e.target.value;
    setProjectMode(mode);
    if (mode === 'all') {
      setLoading(true);
      await fetchAllProjects();
      setLoading(false);
    } else {
      // “current” => revert to original
      setEngineeringList(originalEngineering);
      setFilteredEngineering(originalEngineering);
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">Engineering Projects</h1>

        <LegendCP color="fill-green-300" label="Complete" />
        <br/>
        <br/>

        <div className="flex space-x-3 items-center mb-10">
          <button
            onClick={toggleShowApproved}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                       rounded-md shadow-sm text-white bg-red-400 hover:bg-red-700 focus:outline-none
                       focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {showApproved ? 'Show Complete' : 'Hide Complete'}
          </button>

         {/* Dropdown: Current vs. All */}
          <select
            value={projectMode}
            onChange={handleProjectModeChange}
            className="rounded-md border border-gray-300 py-2 px-4"
          >
            <option value="current">Current</option>
            <option value="all">All Projects</option>
          </select>

          <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
        </div>

        {loading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <>
            <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
              <thead>
                <tr className="divide-x divide-gray-400">
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                    ID/Edit
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                    Employee Name
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                    Proposal | Job Site | Eng. Cont.
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-[50px]">
                    Line Riser
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-[50px]">
                    A433 | Paperwork
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                    B45 with FD
                  </th> 
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Notes
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentContracts
                  .filter(engineer =>
                    // Example filter if showApproved is toggling something
                    showApproved && engineer.job_status === 'complete'
                      ? engineer.approved_paperwork !== 1
                      : engineer
                  )
                  .map((engineer, index) => (
                    <tr
                      key={engineer.id}
                      className={`
                        ${engineer.job_status === 'void' ? 'line-through text-red-600' : ''} 
                        ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} 
                        ${engineer.job_status === 'complete' ? 'bg-green-300' :''} 
                        divide-x
                      `}
                    >
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                        <SubMenu label={engineer.id} links={getLinksForJob(engineer)} idItem={engineer.id} />
                        <br />
                        <dl className="font-normal lg:hidden">
                          <dd className="mt-1 truncate text-gray-700">{engineer.employee_name}</dd>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">{engineer.job_address}</dd>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">{engineer.date}</dd>
                        </dl>
                      </td>

                      {/* Employee name */}
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {engineer.employee_name}
                      </td>

                      {/* proposal + job_site + contract_engineer */}
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {engineer.job_number}
                        <br/>
                        <span className="text-gray-400">{engineer.job_site}</span>
                        <br/>
                        <div className="text-green-600 font-semibold">
                          {engineer.contract_engineer || ''}
                        </div>
                        <div>{engineer.description}</div>
                      </td>

                      {/* Riser */}
                      <td className="whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        <div>
                            1LR From Field: {engineer.line_riser_field === 1 ? 
                            <span className='font-bold text-green-600'>Yes</span> : 
                            <span className='font-bold text-red-600'>No</span>}
                        </div> 
                        <div>
                            1LR Signed & Sealed: {engineer.line_riser_signed_sealed === 1 ? 
                            <span className='font-bold text-green-600'>Yes</span> : 
                            <span className='font-bold text-red-600'>No</span>}
                        </div> 
                      </td>

                      {/* A433 */}
                      <td className="whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      <div>
                            A433 Completed: {engineer.a433_complete === 1 ? 
                            <span className='font-bold text-green-600'>Yes</span> : 
                            <span className='font-bold text-red-600'>No</span>}
                        </div> 
                        <div>
                            A433 Returned From Electrician: {engineer.a433_returned_from_elec === 1 ? 
                            <span className='font-bold text-green-600'>Yes</span> : 
                            <span className='font-bold text-red-600'>No</span>}
                        </div> 
                        <div>
                            Paperwork sent to expeditor: {engineer.paperwork_sent_expeditor === 1 ? 
                            <span className='font-bold text-green-600'>Yes</span> : 
                            <span className='font-bold text-red-600'>No</span>}
                        </div> 
                        <div>
                            PAF Received: {engineer.paf_received === 1 ? 
                            <span className='font-bold text-green-600'>Yes</span> : 
                            <span className='font-bold text-red-600'>No</span>}
                        </div> 
                      </td>

                      {/* B45-with-FD */}
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {engineer.b45_with_fd && (
                          <DateFormat date={engineer.b45_with_fd} />
                        )}
                      </td>

                      {/* notes */}
                      <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                        {engineer.engineering_notes && (
                          <Truncate
                            text={engineer.engineering_notes}
                            handleNotesView={() => handleNotesView(engineer.id)}
                          />
                        )}
                        {notesIdOpen === engineer.id && (
                          <ModalWindow
                            job={engineer.engineering_notes}
                            closeNotes={() => setNotesIdOpen(null)}
                            activeButton={true}
                          />
                        )}
                      </td>

                      {/* date */}
                      <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell">
                        <DateFormat date={engineer.date} />
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>
            {/* <-- NEW: pagination controls */}
            <div className="flex justify-center items-center mt-4 space-x-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 border rounded disabled:opacity-50"
              >
                Prev
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 border rounded disabled:opacity-50"
              >
                Next
              </button>

              {/* optional: change page size */}
              <select
                className="ml-4"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(+e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>  
          </>
        )}
      </div>

      {/* 7) Render the new modal for editing */}
      <EngineeringModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={handleModalSubmit}
        initialData={editData || {}}
      />
    </>
  );
};

export default Engineering;

import React, { useState, useEffect } from 'react';
import Badge from '../components/Badge';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import DateFormat from '../components/DateFormat';
import CurrencyFormat from '../components/CurrencyFormat';
import SearchBarCP from '../components/SearchBarCP';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../custom.css';
import LegendCP from '../components/LegendCP';
import SubmitButton from '../components/SubmitButton';
import { useUserData } from '../context/UserData';
import LoadingSpinner  from '../components/LoadingSpinner'

const InvoiceUpdate = () => {
  const { key } = useParams();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showVoid, setShowVoid] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/send_invoice_update.php`;
  const userData = useUserData();
  const [searchTerm, setSearchTerm] = useState('');
  const [modifiedJobs, setModifiedJobs] = useState({});

  const taxBadge = () => {
    return { color: "purple", num: "800" };
  }

  const preWageClass = "hidden px-3 py-4 text-sm text-gray-500 sm:table-cell bg-green-300";

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilteredJobs(jobs);
    } else {
      const filtered = jobs.filter(object =>
        (object.job_description && object.job_description.toLowerCase().includes(value.toLowerCase())) ||
        (object.job_number && object.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (object.job_site && object.job_site.toLowerCase().includes(value.toLowerCase())) ||
        (object.customer_name && object.customer_name.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredJobs(filtered);
    }
  };

  const getBadgeProps = (type) => {
    switch (type) {
      case 'New System - FA':
        return { color: "orange", num: "500" };
      case 'Upgrade':
        return { color: "yellow", num: "900" };
      case 'Floor Work':
        return { color: "indigo", num: "900" };
      case 'Repair':
        return { color: "green", num: "900" };
      case 'Filing':
        return { color: "brown", num: "900" };
      default:
        return { color: "gray", num: "900" };
    }
  };

  const toggleShowVoid = () => {
    setShowVoid(!showVoid);
  };

  const handleChange = (event, id) => {
    const { name, value } = event.target;
    let adjustedValue = value;

    if (name === 'invoiced') {
      adjustedValue = Math.max(0, Math.min(100, Number(value)));
    }

    const updatedJob = jobs.find(job => job.id === id);
    if (updatedJob) {
      updatedJob[name] = adjustedValue;
      setModifiedJobs(prev => ({ ...prev, [id]: updatedJob }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(api, {
        headers: { 'Content-Type': 'application/json' },
        updatedJobs: Object.values(modifiedJobs)
      });
      if (response.status === 200) {
        toast.success("Jobs updated and email sent successfully!");
        setModifiedJobs({});
      } else {
        toast.error("Error updating jobs.");
      }
    } catch (error) {
      //console.error("Submission error:", error);
      toast.error("Failed to submit jobs.");
    }
  };

  useEffect(() => {
    if (userData && userData.users) {
      if (!userData.users.pm && userData.users.department !== 'Admin' && userData.users.department !== 'Sales') {
        navigate('/');
      }
    }
    const pm = userData?.users.department === 'Admin' ? 'aj' : userData?.users.pm;

    const fetchJobs = async () => {
      setLoading(true)
      try {
        const response = await axios.get(`${apiUrl}/wip_crud.php?getPmList=${pm}`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        if (response.data.error === 'Authentication required') {
          navigate('/Login');
        } else if (response.data.error === 'no access') {
          navigate('/');
        } else {
          setJobs(response.data.list || []);
          setFilteredJobs(response.data.list || []);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [key, apiUrl, navigate, userData]);

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-10">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex-auto mt-8 mb-10">
            <h1 className="text-2xl font-semibold text-gray-500">
              Invoice % Update
            </h1>
          </div>
          <LegendCP color="fill-green-300" label="Prevailing Wage" />
          <LegendCP color="fill-red-300" label="Credit Hold" />
        </div>
        <div className="-mx-4 mt-8">
          <div className="flex-grow py-6 max-w-6xl mx-auto">
            <div className="relative rounded-md shadow-sm flex-grow">
              <input
                type="text"
                name="search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder="Filter" />
            </div>
          </div>
          {loading ? (
            <LoadingSpinner children={'Loading...'}/>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <div className='mx-auto max-w-6xl'>
                <SubmitButton children={'Submit'}/>
                <br/>
                <br/>
                  <table className="min-w-full divide-y divide-x divide-gray-300 bg-gray-200 border rounded-lg">
                    <thead>
                      <tr className='divide-x divide-gray-400'>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                          ID
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          Proposal #
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          Job Site | Customer
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          Job Description
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          F/P
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          Price | Tax
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          I%
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          % I Update
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          P%
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          PM
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {filteredJobs.filter(job => showVoid ? job.status === 'True' : job.status !== 'True').map((job, index) => (
                        <tr key={job.id} className={`${job.status === 'True' ? 'line-through text-red-600' : ''} ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-2">
                            {job.id}
                            <dl className="font-normal lg:hidden">
                              <dd className="mt-1 truncate text-gray-700">{job.company}</dd>
                              <dd className="mt-1 truncate text-gray-500 sm:hidden">{job.contractor}</dd>
                              <dd className="mt-1 truncate text-gray-500 sm:hidden">{job.jobnum}</dd>
                            </dl>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <p className='text-[12px]'>
                              <NavLink to={'#'}>
                                <button className='text-blue-500'>{job.job_number}</button>
                              </NavLink>
                            </p>
                            <p className="text-[10px] text-gray-500">
                              <Badge feed={job.type} {...getBadgeProps(job.type)} />
                            </p>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <p className="text-sm font-medium text-gray-900">{job.job_site}</p>
                            <p className="text-sm text-gray-500">{job.customer_name}</p>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {job.job_description}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${job.f_p_type === 'F' ? 'bg-green-50 text-green-700' : 'bg-yellow-50 text-yellow-800'} ring-1 ring-inset ring-green-600/20`}>
                              {job.f_p_type}
                            </span>
                          </td>
                          <td className={job.prev_wage ? preWageClass : "hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"}>
                            {<CurrencyFormat value={job.price} />}
                            <p><Badge feed={job.tax_status} {...taxBadge()} /></p>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{job.invoiced}%</td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <div className="sm:col-span-1">
                              <div className="mt-1">
                                <input
                                  type="number"
                                  name="invoiced"
                                  id="invoiced"
                                  maxLength="3"
                                  value={job.invoiced || ''}
                                  max="100"
                                  onChange={(e) => handleChange(e, job.id)}
                                  autoComplete="invoiced"
                                  className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{job.paid}%</td>
                          <td className="py-4 pl-1 text-sm text-gray-500"><DateFormat date={job.date} /></td>
                          <td className="py-4 pl-1 text-sm text-gray-500">{job.pm}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br/>
                  <SubmitButton children={'Submit'} />
                </div>
                <div className='space-x-4'><br /></div>
              </form>
            </>
          )}
          
        </div>
      </div>
    </>
  );
};

export default InvoiceUpdate;

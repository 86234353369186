import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubmitButton from '../components/SubmitButton';
import CurrencyFormat from '../components/CurrencyFormat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectCustomers from '../components/SelectCustomers';

const ProposalNew = () => {
  const [revisionChecked, setRevisionChecked] = useState(false);
  const [changeOrderChecked, setChangeOrderChecked] = useState(false);
  const [allBiddersChecked, setAllBiddersChecked] = useState(false);
  const navigate = useNavigate();
  const { id, key } = useParams();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [proposal, setProposal] = useState({
    id: '',
    date: '',
    company: '',
    job_address: '',
    description: '',
    sentto: '',
    price: '',
    jobnum: '',
    revision: '',
    void: '',
    change_order: false,
    revised_id: '',
    changed_order_Id: '',
    all_bidder: false
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/proposal_list_crud.php`;

  function capitalizeFirstLetter(str) { 
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const fetchProposal = async () => {
      if (key === 'edit') {
        try {
          const response = await fetch(`${apiUrl}/proposal_list_crud.php?getDetail=${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if(data.revised_id) {
            navigate('/proposal_list');
            toast.info('This proposal is already revised.', {
              position: 'top-center'
            });
            return
          }

          if(data.changed_order_id > 1) {
            navigate('/proposal_list');
            toast.info('active newer change order', {
              position: 'top-center'
            });
            return
          }
          
          if (data.error) {
            navigate('/proposal_list');
            toast.error(data.error, { position: 'top-center' });
          } else {
            setProposal(data ?? {});
            setSelectedCompany({ customer_name: data.customer_name ?? '' });
          }
        } catch (error) {
          //console.error('Error fetching data', error);
          toast.error('Failed to fetch proposal details.');
          setProposal({});
          setSelectedCompany({});
        }
      } else if (id === 'active') {
        setProposal({ jobnum: '' });
      } else {
        setProposal({ jobnum: id });
      }
    };

    fetchProposal();
  }, [id, key, navigate, apiUrl]);

  useEffect(() => {
    if (selectedCompany && selectedCompany.customer_name) {
      setProposal(prev => ({ ...prev, customer_name: selectedCompany.customer_name }));
    }
  }, [selectedCompany]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setProposal(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleRevision = (e) => {
    setRevisionChecked(e.target.checked);
  }

  const handleChangeOrder = (e) => {
    setChangeOrderChecked(e.target.checked);
  }

  const handleCompanySelect = newCompany => {
    if (newCompany && newCompany.customer_name) {
      setSelectedCompany(newCompany);
      setProposal(prev => ({
        ...prev,
        customer_name: newCompany.customer_name,
        companyId: newCompany.id
      }));
    } else {
      //console.error('Invalid company selected:', newCompany);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    const bodyData = {
      status: 'submit',
      key: key,
      revision_check: revisionChecked,
      change_order_check: changeOrderChecked,
      data: {
        ...proposal,
        all_bidder: allBiddersChecked // Ensure all_bidder is set correctly
      }
    };
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if(response.data.success) {
        toast.info(response.data.success, {
          position: 'top-center'
        });

        setTimeout(() => {
          navigate('/proposal_list');
        }, 500); // Delay navigation for 2 seconds
      } else {
        toast.error(response.data.error, {
          position: 'top-center'
        });
      }

    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 py-5">
      <form onSubmit={handleSubmit} className='w-[800px] px-2 bg-white border rounded-lg py-8 px-8'>

        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">{capitalizeFirstLetter(key)} Proposal</h2>
            <p className="mt-1 text-sm leading-6 text-red-600">
              * Denotes Required Fields
            </p>
          </div>
          
          {proposal.approved ? 
            (
              <>
                <p className='text-sm'>{proposal.jobnum}</p>
                <div className='text-gray-400'>
                <span className='text-gray-900'>{proposal.customer_name}</span><br/>
                <span>{<CurrencyFormat value={proposal.price}/>}</span><br/> 
                <span>{proposal.job_address}</span> <br/> <br/>

                <div className='sm:col-span-6'>
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                      Project Description <span className='text-red-500'>*</span>
                    </label>
                    <div className="mt-2">
                      <textarea
                        rows={4}
                        name="description"
                        id="description"
                        value={proposal.description || ''}
                        onChange={handleChange}
                        className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                </div>
              </>
            ) 
            :
            (
              <div className="border-b border-gray-900/10 pb-12">
                <h6 className="text-base font-semibold leading-7 text-gray-600">:  {proposal.jobnum}</h6>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                {id !== 'active'  && (
                  <div className="sm:col-span-6">
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          id="all_bidders"
                          onChange={() => setAllBiddersChecked(!allBiddersChecked)}
                          type="checkbox"
                          name="all_bidder"
                          checked={allBiddersChecked}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor="all_bidders" className="font-medium text-gray-900">
                          All Bidders
                        </label>
                      </div>
                    </div>
                  </div>
                )}  
                  {!allBiddersChecked && (
                    <>
                     <div className="sm:col-span-3">
                      <SelectCustomers
                        label="Select a Company"
                        items={companies}
                        selectedItem={selectedCompany}
                        setSelectedItem={handleCompanySelect}
                        apiUrl={`${apiUrl}/customer_crud.php`}
                      />
                    </div>
                    </>
                  )}

                  {id === 'active' && (
                    <div className="sm:col-span-3">
                    <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                      Job Number (no dashes) <span className='text-red-500'>* </span> 
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="jobnum"
                        id="jobnum"
                        value={proposal.jobnum || ''}
                        onChange={handleChange}
                        required={true}
                        autoComplete="jobnum"
                        className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  )}


                  <div className="sm:col-span-3">
                    <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                      Price (only digits) <span className='text-red-500'>* </span> 
                      :: <span className='text-gray-400'>${<CurrencyFormat value={proposal.price || ''}/> || ''}</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="price"
                        id="price"
                        value={proposal.price || ''}
                        onChange={handleChange}
                        autoComplete="price"
                        className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="sentto" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="sentto"
                        name="sentto"
                        type="sentto"
                        value={proposal.sentto || ''}
                        onChange={handleChange}
                        autoComplete="sentto"
                        className="text-slate-500 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="job_address" className="block text-sm font-medium leading-6 text-gray-900">
                      Project Address <span className='text-red-500'>*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="job_address"
                        id="job_address"
                        value={proposal.job_address || ''} 
                        onChange={handleChange}
                        autoComplete="job_address"
                        className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-6'>
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                      Project Description <span className='text-red-500'>*</span>
                    </label>
                    <div className="mt-2">
                      <textarea
                        rows={4}
                        name="description"
                        id="description"
                        value={proposal.description || ''}
                        onChange={handleChange}
                        className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                </div>
              </div>
            )
          }

          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-2 space-y-10">
              <fieldset>
                <div className="space-y-6">
                  { (key !== 'new' && !proposal.void && !proposal.change_order && !changeOrderChecked) && (
                    <>
                      <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                          <input
                            id="revision"
                            onChange={handleRevision}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="revision" className="font-medium text-gray-900">
                            R{proposal.revision ? proposal.revision + 1 : 1} (Revision)
                          </label>
                        </div>
                      </div>
                    </>
                  )}

                  { (key !== 'new' && !proposal.void && !revisionChecked) && (
                    <>
                      <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                          <input
                            id="change_order"
                            onChange={handleChangeOrder}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <br/>                       

                        <div className="text-sm leading-6">
                          <label htmlFor="change_order" className="font-medium text-gray-900">
                            CO{proposal.change_order ? proposal.change_order + 1 : 1} (change order)
                          </label>
                        </div>
                      </div>
                      
                      {changeOrderChecked && (
                        <>
                          <div className="sm:col-span-3">
                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                              Price (only digits) <span className='text-red-500'>* </span>
                              :: <span className='text-gray-400'>{<CurrencyFormat value={proposal.price || ''}/> || ''}</span>
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                name="price"
                                id="price"
                                value={proposal.price || ''}
                                onChange={handleChange}
                                autoComplete="price"
                                className="text-slate-500 block w-1/4 rounded-md border-0 py-1.5
                                 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                          </div>
                        </>
                      )}                     
                    </>
                  )}

                  {proposal.approved ? ('') : (
                   <>
                    { (key !== 'new' && !revisionChecked && !changeOrderChecked) && (
                      <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                          <input
                            id="void"
                            name="void"
                            checked={proposal.void || ''}
                            onChange={handleChange}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>

                        <div className="text-sm leading-6">
                          <label htmlFor="void" className="font-medium text-gray-900">
                            Void
                          </label>
                        </div>
                      </div>
                    )}
                    </>
                  )}


                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <NavLink to="/proposal_list">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
          </NavLink>
          <SubmitButton
            disabled={
              (!allBiddersChecked && !selectedCompany) ||
              !proposal.price ||
              !proposal.job_address ||
              !proposal.description
            }
            children={'Submit'}
          /> 
        </div>
      </form>
      
    </div>
  )
}

export default ProposalNew;
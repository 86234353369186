import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EngineeringModal = ({
  isOpen,
  onClose,
  onSubmit,       // Function to call on final submit
  initialData = {} // For editing an existing row
}) => {
  // Check if we're editing or creating new
  const isEdit = !!initialData.id;

  // Fields from your new table:
  // 1) id
  // 2) job_number
  // 3) job_site
  // 4) engineering_notes
  // 5) employee_name
  // 6) date
  // 7) contract_engineer
  // 8) line_riser_field (tinyint, treat as boolean)
  // 9) line_riser_signed_sealed (tinyint, treat as boolean)
  // 10) tb60_complete (tinyint, treat as boolean)
  // 11) a433_complete (tinyint, treat as boolean)
  // 12) a433_returned_from_elec (tinyint, treat as boolean)
  // 13) paperwork_sent_expeditor (tinyint, treat as boolean)
  // 14) b45_with_fd (date)
  // 15) paf_received (tinyint, treat as boolean)

  const [id, setId] = useState(null);
  const [jobNumber, setJobNumber] = useState('');
  const [jobSite, setJobSite] = useState('');
  const [engineeringNotes, setEngineeringNotes] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [engDate, setEngDate] = useState('');
  const [contractEngineer, setContractEngineer] = useState('');

  // Boolean/tinyint fields
  const [lineRiserField, setLineRiserField] = useState(false);
  const [lineRiserSignedSealed, setLineRiserSignedSealed] = useState(false);
  const [tb60Complete, setTb60Complete] = useState(false);
  const [a433Complete, setA433Complete] = useState(false);
  const [a433ReturnedFromElec, setA433ReturnedFromElec] = useState(false);
  const [paperworkSentExpeditor, setPaperworkSentExpeditor] = useState(false);
  const [pafReceived, setPafReceived] = useState(false);

  // Additional date
  const [b45WithFd, setB45WithFd] = useState('');

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL; // If needed

  // -------------- Prefill if editing --------------
  useEffect(() => {
    if (isEdit) {
      setId(initialData.id || null);
      setJobNumber(initialData.job_number || '');
      setJobSite(initialData.job_site || '');
      setEngineeringNotes(initialData.engineering_notes || '');
      setEmployeeName(initialData.employee_name || '');
      setEngDate(initialData.date || '');
      setContractEngineer(initialData.contract_engineer || '');

      // Convert tinyint to boolean
      setLineRiserField(!!initialData.line_riser_field);
      setLineRiserSignedSealed(!!initialData.line_riser_signed_sealed);
      setTb60Complete(!!initialData.tb60_complete);
      setA433Complete(!!initialData.a433_complete);
      setA433ReturnedFromElec(!!initialData.a433_returned_from_elec);
      setPaperworkSentExpeditor(!!initialData.paperwork_sent_expeditor);
      setPafReceived(!!initialData.paf_received);

      setB45WithFd(initialData.b45_with_fd || '');
    } else {
      // New entry
      setId(null);
      setJobNumber('');
      setJobSite('');
      setEngineeringNotes('');
      setEmployeeName('');
      setEngDate('');
      setContractEngineer('');
      setLineRiserField(false);
      setLineRiserSignedSealed(false);
      setTb60Complete(false);
      setA433Complete(false);
      setA433ReturnedFromElec(false);
      setPaperworkSentExpeditor(false);
      setPafReceived(false);
      setB45WithFd('');
    }
  }, [isEdit, initialData]);

  // -------------- If not open, return null --------------
  if (!isOpen) return null;

  // -------------- handleSubmit logic --------------
  const handleSubmit = (e) => {
    e.preventDefault();

    // We'll convert booleans => 0/1
    const formData = {
      data: {
        id,
        job_number: jobNumber,
        job_site: jobSite,
        engineering_notes: engineeringNotes,
        employee_name: employeeName,
        date: engDate,
        contract_engineer: contractEngineer,

        line_riser_field: lineRiserField ? 1 : 0,
        line_riser_signed_sealed: lineRiserSignedSealed ? 1 : 0,
        tb60_complete: tb60Complete ? 1 : 0,
        a433_complete: a433Complete ? 1 : 0,
        a433_returned_from_elec: a433ReturnedFromElec ? 1 : 0,
        paperwork_sent_expeditor: paperworkSentExpeditor ? 1 : 0,
        paf_received: pafReceived ? 1 : 0,

        b45_with_fd: b45WithFd,
      },
      // The status is up to you or your backend
      // status: isEdit ? 'update_engineering' : 'submit_engineering'
      status: 'submit_engineering'
    };

    onSubmit(formData);
  };

  // -------------- Render the Modal --------------
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded shadow-lg w-11/12 max-w-3xl p-6 relative">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-2">
          <h3 className="text-lg font-bold">
            {isEdit ? 'Edit Engineering Tracking' : 'New Engineering Tracking'}
          </h3>
          <button onClick={onClose} className="text-gray-800 hover:text-gray-800">&times;</button>
        </div>

        {/* Body / Form */}
        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          <p className="text-sm text-gray-800">* Denotes Required Field</p>

          {isEdit && (
            <input type="hidden" value={id || ''} readOnly />
          )}

          {/* Row 1: job_number + job_site */}
          <div className="flex space-x-4">
            <div className="flex flex-col w-1/2">
              <label className="text-gray-800">
                Job Number <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                value={jobNumber}
                onChange={(e) => setJobNumber(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                required
              />
            </div>

            <div className="flex flex-col w-1/2">
              <label className="text-gray-800">
                Job Site <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                value={jobSite}
                onChange={(e) => setJobSite(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                required
              />
            </div>
          </div>

          {/* Row 2: employee_name + date */}
          <div className="flex space-x-4">
          {/* Row 6: b45_with_fd date */}
          <div className="flex flex-col w-1/2">
            <label className="text-gray-800">B45 With FD (Date)</label>
            <input
              type="date"
              value={b45WithFd}
              onChange={(e) => setB45WithFd(e.target.value)}
              className="border rounded w-full p-2 mt-1 text-sm"
            />
          </div>

            <div className="flex flex-col w-1/2">
              <label className="text-gray-800">
                Date
              </label>
              <input
                type="date"
                value={engDate}
                onChange={(e) => setEngDate(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
          </div>

          {/* Row 3: contract_engineer */}
          <div className="flex flex-col">
            <label className="text-gray-800">Contract Engineer</label>
            <input
              type="text"
              value={contractEngineer}
              onChange={(e) => setContractEngineer(e.target.value)}
              className="border rounded w-full p-2 mt-1 text-sm"
            />
          </div>

          {/* Row 4: line_riser_field, line_riser_signed_sealed, tb60_complete, a433_complete */}
          <div className="flex space-x-4 items-center bg-yellow-50 p-3 rounded-md">
            <div className="flex items-center space-x-2 w-1/4">
              <input
                id="lineRiserField"
                type="checkbox"
                checked={lineRiserField}
                onChange={(e) => setLineRiserField(e.target.checked)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="lineRiserField" className="text-gray-800 text-sm">
                Line Riser Field
              </label>
            </div>

            <div className="flex items-center space-x-2 w-1/4">
              <input
                id="lineRiserSignedSealed"
                type="checkbox"
                checked={lineRiserSignedSealed}
                onChange={(e) => setLineRiserSignedSealed(e.target.checked)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="lineRiserSignedSealed" className="text-gray-800 text-sm">
                Line Riser Signed
              </label>
            </div>

            <div className="flex items-center space-x-2 w-1/4">
              <input
                id="tb60Complete"
                type="checkbox"
                checked={tb60Complete}
                onChange={(e) => setTb60Complete(e.target.checked)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="tb60Complete" className="text-gray-800 text-sm">
                TB60 Complete
              </label>
            </div>

            <div className="flex items-center space-x-2 w-1/4">
              <input
                id="a433Complete"
                type="checkbox"
                checked={a433Complete}
                onChange={(e) => setA433Complete(e.target.checked)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="a433Complete" className="text-gray-800 text-sm">
                A433 Complete
              </label>
            </div>
          </div>

          {/* Row 5: a433_returned_from_elec, paperwork_sent_expeditor, paf_received */}
          <div className="flex space-x-4 items-center bg-yellow-50 p-3 rounded-md">
            <div className="flex items-center space-x-2 w-1/3">
              <input
                id="a433ReturnedFromElec"
                type="checkbox"
                checked={a433ReturnedFromElec}
                onChange={(e) => setA433ReturnedFromElec(e.target.checked)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="a433ReturnedFromElec" className="text-gray-800 text-sm">
                A433 Returned Elec
              </label>
            </div>

            <div className="flex items-center space-x-2 w-1/3">
              <input
                id="paperworkSentExpeditor"
                type="checkbox"
                checked={paperworkSentExpeditor}
                onChange={(e) => setPaperworkSentExpeditor(e.target.checked)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="paperworkSentExpeditor" className="text-gray-800 text-sm">
                Paperwork Sent Expeditor
              </label>
            </div>

            <div className="flex items-center space-x-2 w-1/3">
              <input
                id="pafReceived"
                type="checkbox"
                checked={pafReceived}
                onChange={(e) => setPafReceived(e.target.checked)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="pafReceived" className="text-gray-800 text-sm">
                PAF Received
              </label>
            </div>
          </div>


          {/* Row 7: engineering_notes (textarea) */}
          <div className="flex flex-col">
            <label className="text-gray-800">Engineering Notes</label>
            <textarea
              rows={3}
              value={engineeringNotes}
              onChange={(e) => setEngineeringNotes(e.target.value)}
              className="border rounded w-full p-2 mt-1 text-sm resize-none"
              placeholder="Any additional info..."
            />
          </div>

          {/* Buttons */}
          <div className="text-right mt-4">
            <button
              onClick={onClose}
              type="button"
              className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded text-sm mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded text-sm"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EngineeringModal;

import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import SideNav from '../components/SideNav';
import HeadingCp from '../components/HeadingCp';
import AvatarLocation from '../components/AvatarLocation';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';

const CheckInOutPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;

  // ---------------------------------------
  // 1) Local State
  // ---------------------------------------
  // For searching by name or job location
  const [searchTerm, setSearchTerm] = useState('');

  // Loading indicator
  const [loading, setLoading] = useState(false);

  // This is the main array of data returned by your backend
  const [checkInOutData, setCheckInOutData] = useState([]);

  // Track whether user has clicked Filter (to switch layout)
  const [isFiltered, setIsFiltered] = useState(false);

  // For navigation (redirects on auth error, etc.)
  const navigate = useNavigate();

  // For range-based filter: date from + date to
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  // For employee dropdown
  const [employees, setEmployees] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState('');

  // ---------------------------------------
  // 2) Fetch All CheckIn/Out Data (Initial & Reset)
  // ---------------------------------------
  const fetchCheckInOutData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(api, {
        // The backend sees `vcheckinout=true` => returns entire list
        params: { vcheckinout: true },
        headers: { 'Content-Type': 'application/json' },
      });

      if (Array.isArray(response.data)) {
        if (response.data.length === 0) {
          toast.error('No records found!', { position: 'top-center' });
        }
        setCheckInOutData(response.data);
      } else {
        setCheckInOutData([]);
      }
    } catch (error) {
      console.error('Error fetching check-in/out data:', error);
      // Basic auth & permission checks
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setIsFiltered(false);  // Ensure we revert to old layout when Reset is used
      setLoading(false);
    }
  };

  // Run fetch on mount
  useEffect(() => {
    fetchCheckInOutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, navigate]);

  // ---------------------------------------
  // 3) Fetch Employees for the dropdown
  // ---------------------------------------
  useEffect(() => {
    setLoading(true);
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const employeeList = Array.isArray(response.data) ? response.data : [];
        if (employeeList.length === 0) {
          toast.error("No employees found!", { position: "top-center" });
        }
        setEmployees(employeeList);
      } catch (error) {
        console.error('Error fetching employees:', error);
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [apiUrl, navigate]);

  // ---------------------------------------
  // 4) Filter by Range & Employee
  // ---------------------------------------
  const handleFilter = async () => {
    // Ensure user selected a date range & employee
    if (!filterEmployee || !dateFrom || !dateTo) {
      toast.error('Please select an employee and both FROM & TO dates.', { position: 'top-center' });
      return;
    }

    setLoading(true);
    const bodyData = {
      status: 'getFilter',
      date_from: dateFrom,
      date_to: dateTo,
      employee_id: filterEmployee,
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (Array.isArray(response.data)) {
        if (response.data.length === 0) {
          toast.info("No records found for the selected criteria.", { position: "top-center" });
        }
        setCheckInOutData(response.data);
        setIsFiltered(true);  // Switch to the "days" design
      } else {
        toast.error("Unexpected filter result format!", { position: "top-center" });
        setCheckInOutData([]);
      }
    } catch (error) {
      console.error('Error filtering range:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------
  // 5) Search Logic
  // ---------------------------------------
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter data by search term (employee name)
  const filteredData = checkInOutData.filter((emp) => {
    const employeeName = emp.employee?.toLowerCase() || '';
    return employeeName.includes(searchTerm.toLowerCase());
  });

  // ---------------------------------------
  // 6) Render
  // ---------------------------------------
  return (
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">
      {/* Side Navigation (Left) */}
      <div className="hidden md:block">
        <div className="pt-6 pr-3">
          <SideNav />
        </div>
      </div>

      {/* Main Content (Center) */}
      <div className="p-4">
        <div className="px-4">
          {/* Heading */}
          <div className="sm:flex sm:items-center">
            <HeadingCp label="Check In/Out Records" />
          </div>

          {/* Search Input */}
          <div className="sm:flex sm:items-center py-1">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <div className="flex-grow">
                <div className="flex space-x-3 items-center mb-8">
                  <div className="relative rounded-md shadow-sm flex-grow">
                    <input
                      type="text"
                      name="search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Search by Name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Filter Bar: FROM / TO / Employee + Buttons */}
          <div className="flex items-center gap-4 max-w-6xl w-full mx-auto mb-6">
            {/* DATE FROM */}
            <div>
              <label className="block text-sm text-gray-700">From</label>
              <input
                type="date"
                className="border border-gray-300 rounded-md px-2 py-1"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </div>

            {/* DATE TO */}
            <div>
              <label className="block text-sm text-gray-700">To</label>
              <input
                type="date"
                className="border border-gray-300 rounded-md px-2 py-1"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </div>

            {/* Employee Dropdown */}
            <div>
              <label className="block text-sm text-gray-700">Employee</label>
              <select
                className="border border-gray-300 rounded-md px-6 py-1"
                value={filterEmployee}
                onChange={(e) => setFilterEmployee(e.target.value)}
              >
                <option value="">Select Employee</option>
                {employees && employees.length > 0 ? (
                  employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.first_name} {employee.last_name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No employees found
                  </option>
                )}
              </select>
            </div>

            {/* Filter & Reset Buttons */}
            <div className="flex items-end gap-2 pt-5">
              <button
                type="button"
                onClick={handleFilter}
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                  shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Filter
              </button>
              <button
                type="button"
                onClick={fetchCheckInOutData}
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                  shadow-sm text-white bg-gray-500 hover:bg-gray-700 focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Reset
              </button>
            </div>
          </div>

          {/* Data Cards */}
          {loading ? (
            <LoadingSpinner>Loading...</LoadingSpinner>
          ) : (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {filteredData.length > 0 ? (
                filteredData.map((emp) => (
                  <div
                    key={emp.tech_id}
                    className="relative flex flex-col rounded-lg border border-gray-300 bg-white p-4 shadow-sm 
                               hover:border-gray-400"
                  >
                    {/* Technician Header: Name & Avatar */}
                    <div className="flex items-center space-x-3 mb-3">
                      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <AvatarLocation avatar={emp.avatar} className="h12" />
                      </span>
                      <div className="text-lg font-bold text-gray-800">
                        {emp.employee}
                      </div>
                    </div>

                    {/* If NOT filtered => Show old last_am/last_pm/logs layout
                        If filtered => Show the 'days' layout */}
                    {isFiltered ? (
                      /* This is the new 'days' layout */
                      emp.days && Object.keys(emp.days).length > 0 ? (
                        <div className="mt-2 border-t border-gray-200 pt-2 space-y-4">
                          {Object.keys(emp.days).map((dayKey) => {
                            const dayData = emp.days[dayKey]; // { am, pm }
                            return (
                              <div key={dayKey} className="border-b pb-2">
                                <p className="text-sm font-semibold text-gray-800 mb-1">
                                  {dayKey}
                                </p>
                                {dayData.am ? (
                                  <div className="mb-1">
                                    <p className="text-xs text-blue-600">AM</p>
                                    <p className="text-xs text-gray-700">
                                      Location: {dayData.am.job_location}
                                    </p>
                                    <p className="text-xs text-gray-500">
                                      Time: {new Date(dayData.am.time * 1000).toLocaleTimeString()}
                                      {" | "} 
                                      Status: {dayData.am.check_status}
                                    </p>
                                  </div>
                                ) : (
                                  <p className="text-xs text-red-500">No AM record</p>
                                )}

                                {dayData.pm ? (
                                  <div>
                                    <p className="text-xs text-blue-600">PM</p>
                                    <p className="text-xs text-gray-700">
                                      Location: {dayData.pm.job_location}
                                    </p>
                                    <p className="text-xs text-gray-500">
                                      Time: {new Date(dayData.pm.time * 1000).toLocaleTimeString()}
                                      {" | "}
                                      Status: {dayData.pm.check_status}
                                    </p>
                                  </div>
                                ) : (
                                  <p className="text-xs text-red-500">No PM record</p>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <p className="text-sm text-red-500 mt-2">No days found.</p>
                      )
                    ) : (
                      /* This is the old approach: last_am / last_pm / logs */
                      <div>
                        {/* Last AM */}
                        {emp.last_am ? (
                          <div className="mb-3 border-t border-gray-200 pt-2">
                            <p className="text-sm text-blue-600 font-medium">Last AM</p>
                            <p className="text-sm text-gray-700">
                              Location: {emp.last_am.job_location}
                            </p>
                            <p className="text-sm text-gray-500">
                              Date: <DateFormat date={emp.last_am.date} /> &nbsp;
                              Time: {new Date(emp.last_am.time * 1000).toLocaleTimeString()}
                            </p>
                          </div>
                        ) : (
                          <div className="mb-3 border-t border-gray-200 pt-2">
                            <p className="text-sm text-red-500 font-medium">No AM record found</p>
                          </div>
                        )}

                        {/* Last PM */}
                        {emp.last_pm ? (
                          <div className="border-t border-gray-200 pt-2">
                            <p className="text-sm text-blue-600 font-medium">Last PM</p>
                            <p className="text-sm text-gray-700">
                              Location: {emp.last_pm.job_location}
                            </p>
                            <p className="text-sm text-gray-500">
                              Date: <DateFormat date={emp.last_pm.date} /> &nbsp;
                              Time: {new Date(emp.last_pm.time * 1000).toLocaleTimeString()}
                            </p>
                          </div>
                        ) : (
                          <div className="border-t border-gray-200 pt-2">
                            <p className="text-sm text-red-500 font-medium">No PM record found</p>
                          </div>
                        )}

                        {/* All logs */}
                        {emp.logs && emp.logs.length > 0 && (
                          <div className="mt-3 border-t border-gray-200 pt-2">
                            <p className="text-sm font-medium text-blue-600">All Logs:</p>
                            <ul className="list-disc pl-5 text-sm text-gray-700">
                              {emp.logs.map((logItem, idx) => (
                                <li key={idx}>
                                  Date: {logItem.date}, 
                                  Time: {new Date(logItem.time * 1000).toLocaleTimeString()}, 
                                  Status: {logItem.check_status}, 
                                  Location: {logItem.job_location}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="p-4 text-center text-gray-500">No records found.</div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Optional Right Sidebar */}
      <div className="hidden md:block">
        <div className="pt-4">
          <div className="border-2">
            <DayOff />
          </div>
          <br />
          <Alerts />
        </div>
      </div>
    </div>
  );
};

export default CheckInOutPage;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const TechServiceHistory = () => {
  const [serviceCall, setServiceCall] = useState([]);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // Fetch data from your PHP endpoint on mount
  useEffect(() => {
    fetchserviceCall();
  }, [apiUrl]);

  const fetchserviceCall = async () => {
    try {
      const response = await axios.get(`${apiUrl}/service_crud.php?serviceHistory=true`);
      if(Array.isArray(response.data) && response.data.length === 0) {
        setServiceCall(response.data || []); // Should be an array of inspection objects
      } else {
        toast.error('No service call data found');
        return;
      }

    } catch (error) {
     if (error.response && error.response.status === 401) {
       navigate('/login');
     } 
    }
  };

  const openModal = (inspectionId) => {
    const item = serviceCall.find((insp) => insp.id === inspectionId);
    setSelectedInspection(item);
  };
  // Close modal
  const closeModal = () => {
    setSelectedInspection(null);
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <h1 className="text-xl font-bold mb-4">Last 10 calls</h1>

      {/* List of serviceCall */}
      <ul className="space-y-2">
        {serviceCall.map((item) => (
          <li
            key={item.id}
            className="bg-white rounded shadow p-3"
          >
              {/* If not canceled, show normal text, else show strikethrough */}
         
                <div className="flex">
                  {/* Date Block with strike */}
                  <div className="mr-3 text-center">
                    <div className="text-2xl font-bold">
                     {item.id}
                    </div>
                    <div className="text-sm text-gray-500">
                      {item.DATE}
                    </div>
                    <div className="text-xs text-blue-500">
                      {item.STATUS}
                    </div>
                  </div>

                  {/* Info Block with strike */}
                  <div>
                    <div className="text-sm text-gray-400">
                      {item.CUSTOMER}
                      <div className='font-bold text-gray-800'>{item.ADDRESS}</div>
                    </div>
                    <div className="text-gray-400 text-xs py-1">
                      by: {item.TECH}
                    </div>
                  </div>
                </div>
          </li>
        ))}
      </ul>

      {/* Modal for selected inspection details */}
      {selectedInspection && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md w-11/12 max-w-md">
            <h3 className="text-lg font-bold mb-4">Details</h3>
            {/* Show details as you did in Angular */}
            <p className="mb-1">
              <span className="font-semibold">Status:</span> {selectedInspection.status}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Tenant:</span> {selectedInspection.tenant}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Address:</span> {selectedInspection.address}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Tech:</span> {selectedInspection.tech}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Inspector:</span> {selectedInspection.inspector}
            </p>
            <p className="mb-1">
              <span className="font-semibold">Date:</span>{' '}
              {selectedInspection.month}/{selectedInspection.day} @ {selectedInspection.startTime}
            </p>
            <p className="mb-4">
              <span className="font-semibold">Notes:</span> {selectedInspection.notes}
            </p>

            {/* Close button */}
            <button
              onClick={closeModal}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TechServiceHistory;

import { Fragment, useState, useEffect } from 'react';
import {
  ArrowUpCircleIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  HandThumbUpIcon,
  HeartIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import AvatarLocation from './AvatarLocation';
import { useUserData } from '../context/UserData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format, parseISO } from 'date-fns';
import DateFormat from './DateFormat';
import DynamicEmoji from './DynamicEmoji';
import ReactionPost from './ReactionPost';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PostComment = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useUserData();
  const navigate = useNavigate();

  // For creating a new post
  const [submitObject, setSubmitObject] = useState({ comment: '', reaction: '' });
  // List of existing comments (posts)
  const [comments, setComments] = useState([]);
  // For replying to an existing comment
  const [replyObject, setReplyObject] = useState({ commentId: null, reply: '' });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Track which comment IDs are expanded (for "View all" / "Show less" logic)
  const [expandedComments, setExpandedComments] = useState([]);

  // Constants
  const MAX_LENGTH = 200;

  // Toggle expand/collapse for a given comment
  const toggleExpandComment = (commentId) => {
    setExpandedComments((prev) => {
      // If already expanded, remove from array => collapse
      if (prev.includes(commentId)) {
        return prev.filter((id) => id !== commentId);
      }
      // Otherwise, add => expand
      return [...prev, commentId];
    });
  };

  // Fetch existing comments on mount
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/post_crud.php?getList=1`, {
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.data) {
          setComments(Array.isArray(response.data) ? response.data : []);
        } else {
          toast.error('No posts found', { position: 'top-center' });
          setComments([]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [apiUrl, navigate, user]);

  // Handle text area changes for new comment
  const handleChange = (e) => {
    setSubmitObject({ ...submitObject, comment: e.target.value });
  };

  // If you want the user to pick a reaction for the *new* comment, handle that here
  const handleNewReactionSelect = (reactionType) => {
    setSubmitObject({ ...submitObject, reaction: reactionType });
  };

  // Submits a new comment (post)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyData = {
      data: submitObject, // e.g. { comment: 'text here', reaction: 'like' }
      status: 'addComment',
    };
    try {
      const response = await axios.post(`${apiUrl}/post_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data.success) {
        // Prepend new comment
        setComments([response.data.comment, ...comments]);
        // Reset form
        setSubmitObject({ comment: '', reaction: '' });
      } else {
        toast.error(response.data.error || 'Failed adding post.', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error('No access to add.', { position: 'top-center' });
      }
    }
  };

  // For handling typed replies on existing comments
  const handleReplyChange = (e, commentId) => {
    setReplyObject({ commentId, reply: e.target.value });
  };

  // Submits a reply to an existing comment
  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();
    const bodyData = {
      data: { reply: replyObject.reply },
      status: 'addReply',
      post_id: commentId,
    };
    try {
      const response = await axios.post(`${apiUrl}/post_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data.success) {
        // Insert new reply at top of existing replies
        setComments(
          comments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  reply: [response.data.reply, ...(comment.reply || [])],
                }
              : comment
          )
        );
        // Clear reply input
        setReplyObject({ commentId: null, reply: '' });
      } else {
        toast.error(response.data.error || 'Failed to update.', { position: 'top-center' });
      }
    } catch (error) {
      toast.error('Issue updating data.', { position: 'top-center' });

      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error('No access to update.', { position: 'top-center' });
      }
    }
  };

  // Deletes a comment
  const handleDelete = async (commentId) => {
    try {
      const response = await axios.post(
        `${apiUrl}/post_crud.php`,
        {
          data: { post_id: commentId },
          status: 'delete_comment',
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (response.data.success) {
        toast.success('Comment deleted!', { position: 'top-center' });
        setComments(comments.filter((comment) => comment.id !== commentId));
      } else {
        toast.error(response.data.error || 'Failed to delete comment.', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error('No access to delete.', { position: 'top-center' });
      }
    }
  };

  // Handle a reaction click on an existing comment
  const handleReaction = async (commentId, reactionType) => {
    try {
      // 1) Optimistically update local reaction count
      setComments((prev) =>
        prev.map((comment) => {
          if (comment.id === commentId) {
            // If we have an object with reaction counts, e.g. comment.reactions = { like: 2, smile: 1 }
            const oldCount = comment.reactions?.[reactionType] || 0;
            return {
              ...comment,
              reactions: {
                ...comment.reactions,
                [reactionType]: oldCount + 1,
              },
            };
          }
          return comment;
        })
      );

      // 2) POST to the server (status='reaction')
      const bodyData = {
        status: 'reaction',
        data: {
          post_id: commentId,
          reaction_type: reactionType,
        },
      };
      await axios.post(`${apiUrl}/post_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      // If needed, parse response to see if it was toggled, etc.
    } catch (err) {
      console.error('Error sending reaction:', err);
    }
  };

  // If loading is true, you could show a spinner here, or handle error
  if (loading) {
    return <div className="p-4 text-gray-500">Loading...</div>;
  }
  if (error) {
    return <div className="p-4 text-red-500">Error: {error}</div>;
  }

  return (
    <>
      {/* New Post Form */}
      <div className="flex items-start space-x-4 bg-white p-4 border-2 rounded-lg">
        <div className="flex-shrink-0" />
        <div className="min-w-0 flex-1">
          <form onSubmit={handleSubmit} className="relative">
            <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                rows={2}
                name="comment"
                id="comment"
                value={submitObject.comment}
                onChange={handleChange}
                className="block w-full resize-none border-0 bg-transparent py-1.5 
                           text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm 
                           sm:leading-6"
                placeholder="Add your comment..."
              />
              <div className="py-2" aria-hidden="true">
                <div className="py-px">
                  <div className="h-9" />
                </div>
              </div>
            </div>

            {/* Reaction icons + Post button */}
            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div className="flex items-center space-x-1">
                {/* (Optional) Reaction buttons for the *new* comment */}
              </div>
              <div className="flex-shrink-0">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm 
                             font-semibold text-white shadow-sm hover:bg-indigo-500 
                             focus-visible:outline focus-visible:outline-2 
                             focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Post
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <br />

      {/* List of existing comments */}
      <div>
        {comments.map((comment) => {
          const isExpanded = expandedComments.includes(comment.id);

          // Truncate content
          const truncatedContent =
            comment.content.length > MAX_LENGTH ? comment.content.slice(0, MAX_LENGTH) + '...' : comment.content;

          return (
            <div
              key={comment.id}
              className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mb-4"
            >
              {/* If logged in user is the author, show delete icon */}
              {user?.users?.id === comment.employee_id && (
                <TrashIcon
                  className="h-5 float-end mr-5 mt-5 text-red-600 hover:cursor-pointer hover:text-blue-500"
                  onClick={() => handleDelete(comment.id)}
                />
              )}

              <div className="px-4 py-5 sm:px-6">
                <div className="flex items-center">
                  <AvatarLocation
                    className="h-10 w-10 rounded-full"
                    avatar={comment.avatar}
                  />
                  <div className="pl-2">
                    <p className="text-sm font-semibold text-gray-900">
                      <a
                        href={`/profile/${comment.employee_id}`}
                        className="hover:underline"
                      >
                        {comment.author}
                      </a>
                    </p>
                    <p className="text-sm text-gray-500">
                      {format(parseISO(comment.date), 'MMMM d, yyyy')}
                    </p>
                  </div>
                </div>
              </div>

              <div className="sm:p-6 sm:text-gray-500 p-3">
                {/* Display truncated or full text */}
                <p className="whitespace-pre-wrap mb-3">
                  {isExpanded ? comment.content : truncatedContent}
                </p>

                {/* "View all" / "Show less" button if over 100 chars */}
                {comment.content.length > MAX_LENGTH && (
                  <span
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm 
                               font-semibold text-gray-900 shadow-sm ring-1 ring-inset 
                               ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 w-full hover:cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent page jump
                      toggleExpandComment(comment.id);
                    }}
                  >
                    {isExpanded ? 'Show less' : 'View all'}
                  </span>
                )}

                {/* Replies */}
                {comment.reply && comment.reply.length > 0 && (
                  <div className="mt-3">
                    {comment.reply.map((reply_post) => (
                      <div
                        key={reply_post.id}
                        className="p-4 bg-[#fafafa] rounded-3xl border mb-3"
                      >
                        <div className="flex items-center">
                          <AvatarLocation
                            className="h-7 w-7 rounded-full"
                            avatar={reply_post.avatar}
                          />
                          <div className="pl-2">
                            <p className="text-xs font-semibold text-indigo-700">
                              {reply_post.author}
                            </p>
                            <p className="text-xs text-gray-500">
                              {reply_post.content}
                            </p>
                          </div>
                        </div>
                        <p className="text-xs text-gray-500 pl-8 mt-2">
                          {format(parseISO(reply_post.date), 'MMMM d, yyyy')}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Reaction Section */}
              <ReactionPost comment={comment} handleReaction={handleReaction} />

              {/* Reply input bar */}
              <div className="p-4 flex items-center">
                <AvatarLocation
                  className="h-7 w-7 rounded-full mr-3"
                  avatar={user?.avatar}
                />
                <input
                  type="text"
                  name="reply"
                  id="reply"
                  placeholder="Reply..."
                  value={replyObject.commentId === comment.id ? replyObject.reply : ''}
                  onChange={(e) => handleReplyChange(e, comment.id)}
                  className="text-slate-500 block w-full rounded-full border-0 py-1.5 text-gray-900 
                             shadow-sm ring-1 ring-inset ring-gray-300 
                             placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                             focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <ArrowUpCircleIcon
                  className="h-8 text-green-600 hover:cursor-pointer hover:text-blue-600 ml-2"
                  onClick={(e) => handleReplySubmit(e, comment.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PostComment;

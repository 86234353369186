import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeliveryTrackingModal = ({
  isOpen,
  onClose,
  onSubmit,        // Function to call on final submit
  initialData = {} // For editing an existing row
}) => {
  const isEdit = !!initialData.id;

  // Fields from your table (excluding 'file'):
  // id, job_number, notes, employee_id, empire_po, po_date,
  // vendor, proposal_number, date_proposal, so_number, invoice_number,
  // vendor_price, empire_price

  const [id, setId] = useState(null);
  const [jobNumber, setJobNumber] = useState('');
  const [notes, setNotes] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [empirePo, setEmpirePo] = useState('');
  const [poDate, setPoDate] = useState('');
  const [vendor, setVendor] = useState('');
  const [proposalNumber, setProposalNumber] = useState('');
  const [dateProposal, setDateProposal] = useState('');
  const [soNumber, setSoNumber] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [vendorPrice, setVendorPrice] = useState('');
  const [empirePrice, setEmpirePrice] = useState('');

  // (Optional) employees list
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // -------------------- Prefill if editing --------------------
  useEffect(() => {
    if (isEdit) {
      // Prefill from initialData
      setId(initialData.id || null);
      setJobNumber(initialData.job_number || '');
      setNotes(initialData.notes || '');
      setEmployeeId(initialData.employee_id || '');
      setEmpirePo(initialData.empire_po || '');
      setPoDate(initialData.po_date || '');
      setVendor(initialData.vendor || '');
      setProposalNumber(initialData.proposal_number || '');
      setDateProposal(initialData.date_proposal || '');
      setSoNumber(initialData.so_number || '');
      setInvoiceNumber(initialData.invoice_number || '');
      setVendorPrice(initialData.vendor_price || '');
      setEmpirePrice(initialData.empire_price || '');
    } else {
      // new entry
      setId(null);
      setJobNumber('');
      setNotes('');
      setEmployeeId('');
      setEmpirePo('');
      setPoDate('');
      setVendor('');
      setProposalNumber('');
      setDateProposal('');
      setSoNumber('');
      setInvoiceNumber('');
      setVendorPrice('');
      setEmpirePrice('');
    }
  }, [isEdit, initialData]);

  // -------------------- (Optional) Fetch employees --------------------
  useEffect(() => {
    setLoading(true);
    const fetchEmployees = async () => {
      try {
        // Example if you have an endpoint that returns employees
        const response = await axios.get(
          `${apiUrl}/service_crud.php?employee_list=true`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
          }
        );
        const data = Array.isArray(response.data) ? response.data : [];
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! You don't have access to this area.", {
            position: 'top-center',
          });
        } else {
          toast.error('Error fetching employees', { position: 'top-center' });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [apiUrl, navigate]);

  // -------------------- If modal not open, return null --------------------
  if (!isOpen) return null;

  // -------------------- handleSubmit logic --------------------
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      data: {
        id,
        job_number: jobNumber,
        notes,
        employee_id: employeeId,
        empire_po: empirePo,
        po_date: poDate,
        vendor,
        proposal_number: proposalNumber,
        date_proposal: dateProposal,
        so_number: soNumber,
        invoice_number: invoiceNumber,
        vendor_price: vendorPrice,
        empire_price: empirePrice,
      },
      status: 'submit_new_delivery_tracking', // or something your backend expects
    };
     console.log('Form Data:', formData);
     
    onSubmit(formData);
  };

  // -------------------- Render the Modal --------------------
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded shadow-lg w-11/12 max-w-3xl p-6 relative">
        {/* Modal Header */}
        <div className="flex justify-between items-center border-b pb-2">
          <h3 className="text-lg font-bold">
            {isEdit ? 'Edit Purchase Order' : 'New Purchase Order'}
          </h3>
          <button onClick={onClose} className="text-gray-800 hover:text-gray-800">&times;</button>
        </div>

        {/* Modal Body / Form */}
        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          <p className="text-sm text-gray-800">* Denotes Required Field</p>

          {isEdit && (
            <input type="hidden" value={id || ''} readOnly />
          )}

          {/* Row 1: job_number and employee */}
          <div className="flex space-x-4">
            {/* job_number (required) */}
            <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">
                Job Number <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                value={jobNumber}
                onChange={(e) => setJobNumber(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                required
              />
            </div>

            {/* employee_id dropdown */}
            {/* <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">
                Employee <span className="text-red-600">*</span>
              </label>
              <select
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
                className="border rounded p-2 mt-1 text-sm"
                required
              >
                <option value="">--- Choose Employee ---</option>
                {employees.map((emp) => (
                  <option key={emp.id} value={emp.id}>
                    {emp.first_name} {emp.last_name}
                  </option>
                ))}
              </select>
            </div> */}
          </div>

          {/* Row 2: empire_po, empire_price, invoice_number */}
          <div className="flex space-x-4">
            <div className="flex flex-col w-1/3">
              <label className="block text-gray-800">Empire PO</label>
              <input
                type="text"
                value={empirePo}
                onChange={(e) => setEmpirePo(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label className="block text-gray-800">Empire Price</label>
              <input
                type="number"
                step="0.01"
                value={empirePrice}
                onChange={(e) => setEmpirePrice(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label className="block text-gray-800">Invoice Number</label>
              <input
                type="text"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
          </div>

          {/* Row 3: vendor, po_date, vendor_price, so_number (4 columns) */}
          <div className="flex space-x-4">
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800">
                Vendor <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                required
              />
            </div>
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800">PO Date</label>
              <input
                type="date"
                value={poDate}
                onChange={(e) => setPoDate(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800">Vendor Price</label>
              <input
                type="number"
                step="0.01"
                value={vendorPrice}
                onChange={(e) => setVendorPrice(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800">SO Number</label>
              <input
                type="text"
                value={soNumber}
                onChange={(e) => setSoNumber(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
          </div>

          {/* Row 4: proposal_number, date_proposal */}
          <div className="flex space-x-4">
            <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">Proposal Number</label>
              <input
                type="text"
                value={proposalNumber}
                onChange={(e) => setProposalNumber(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">Date Proposal</label>
              <input
                type="date"
                value={dateProposal}
                onChange={(e) => setDateProposal(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
              />
            </div>
          </div>

          {/* Last Row: notes */}
          <div className="flex flex-col">
            <label className="block text-gray-800">Notes</label>
            <textarea
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="border rounded w-full p-2 mt-1 text-sm resize-none"
              placeholder="Any additional info..."
            />
          </div>

          {/* Buttons */}
          <div className="text-right mt-4">
            <button
              onClick={onClose}
              type="button"
              className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded text-sm mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded text-sm"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeliveryTrackingModal;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import SelectCustomers from '../components/SelectCustomers';
import { toast } from 'react-toastify';
import HeadingCp from '../components/HeadingCp';
import { set } from 'date-fns';

const NewCotContract = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const { id } = useParams();

  // Tabs: "contract", "contact", "building"
  const [activeTab, setActiveTab] = useState('contract');

  // Contract form state
  const [newContractData, setNewContractData] = useState({
    auto_renew: '1',
    exp_date: '',
    customer_id: '',
    contract_address: '',
    contract_city: '',
    contract_state: '',
    contract_zip: '',
    account_number: '',
    password: '',
    date_signed: '',
    activation_date: '',
    frequency: '6',
    contract_price: '',
    sold_by: '',
    notes: '',
    takeover: '1'
  });

  const [contactData, setContactData] = useState({
    id: '',
    contact_name_p: '',
    contact_phone_p: '',
    contact_email_p: '',
    contact_name_s: '',
    contact_phone_s: '',
    contact_email_s: '',
    contact_name_t: '',
    contact_phone_t: '',
    contact_email_t: '',
    contact_name_f: '',
    contact_phone_f: '',
    contact_email_f: ''
  });

  // Building Info form state
  const [buildingData, setBuildingData] = useState({
    BIN_number: '',
    building_classification: '',
    bdoc: '',
    fa_14_submitted: '0',
    fdny_inspection_date: '',
    date_loa: '',
    base_building_loa: '0',
    height: '',
    floors: '',
    floor_area: '',
    system_manufacture: '',
    system_type: '',
    system_panel_location: ''
    // Also cot_contract_id if needed
  });

  // For the <SelectCustomers> component
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);


  useEffect(() => {
    setLoading(true);
    const fetchCotDetail = async () => {
      if (id !== 'new') {
        try {
          const response = await axios.get(`${apiUrl}/cot_crud.php?getDetails=true&&id=${id}`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
          });
          if (response.data[0] === 'success') {
            setNewContractData(response.data.contract);
            if(response.data.contract.customer_id)
            {
              const selectedCompany = response.data.contract
              setSelectedCompany(selectedCompany => {
                return{
                  ...selectedCompany,
                  id: response.data.contract.customer_id,
                  customer_name: response.data.contract.customer_name
                }
              });
            }
            setContactData(response.data.contacts);
            setBuildingData(response.data.building);
            
          } else {
            toast.error('No contract found!', { position: 'top-center' });
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate('/login');
          } else if (error.response && error.response.status === 403) {
            toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
              position: 'top-center',
            });
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchCotDetail();
  }, [id, apiUrl, navigate]);

  useEffect(() => {
   getEmployees();
  }, [apiUrl, navigate]);

  // --------------------------
  // Contract form handlers
  // --------------------------
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewContractData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutoRenewCheckbox = (e) => {
    const checked = e.target.checked;
    setNewContractData((prev) => ({
      ...prev,
      auto_renew: checked ? '1' : '0',
    }));
  };

  const handleCompanySelect = (item) => {
    setSelectedCompany(item);
    setNewContractData((prev) => ({
      ...prev,
      customer_id: item?.id || ''
    }));
  };

  const handleNewContract = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        status: 'addContract',
        data: newContractData
      };

      const response = await axios.post(`${apiUrl}/cot_crud.php`, payload, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.error) {
        toast.error(response.data.error, { position: 'top-center' });
      } else if (response.data.success) {
        toast.success(response.data.success, { position: 'top-center' });
        // Possibly store new contract ID in state here if you need to link contact/building info
        // e.g. contactData.cot_contract_id = response.data.newContractId
        // buildingData.cot_contract_id = response.data.newContractId
      }
    } catch (error) {
      console.error('Error saving contract:', error);
      toast.error('Error saving contract', { position: 'top-center' });
    }
  };

  // --------------------------
  // Contact form handlers
  // --------------------------
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactSave = async (event) => {
    event.preventDefault();
    contactData.cot_contract_id = id;
    try {
      const payload = {
        status: 'addContact',
        data: contactData
      };

      const response = await axios.post(`${apiUrl}/cot_crud.php`, payload, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.error) {
        toast.error(response.data.error, { position: 'top-center' });
      } else if (response.data.success) {
        toast.success(response.data.success, { position: 'top-center' });
        // Possibly navigate or handle linking with contract ID
      }
    } catch (error) {
      console.error('Error saving contact info:', error);
      toast.error('Error saving contact info', { position: 'top-center' });
    }
  };

  // --------------------------
  // Building form handlers
  // --------------------------
  const handleBuildingChange = (e) => {
    const { name, value } = e.target;
    setBuildingData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBuildingSave = async (event) => {
    event.preventDefault();
    buildingData.cot_contract_id = id;
    try {
      const payload = {
        status: 'addBuilding',
        data: buildingData
      };

      const response = await axios.post(`${apiUrl}/cot_crud.php`, payload, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.error) {
        toast.error(response.data.error, { position: 'top-center' });
      } else if (response.data.success) {
        toast.success(response.data.success, { position: 'top-center' });
        navigate('/cot_billing');
      }
    } catch (error) {
      console.error('Error saving building info:', error);
      toast.error('Error saving building info', { position: 'top-center' });
    }
  };

  const getEmployees = async () => {
    try {
      const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      });
      const serviceCalls = Array.isArray(response.data) ? response.data : [];
      if (serviceCalls.length === 0) {
        toast.error('No service calls found!', { position: 'top-center' });
      }
      setEmployees(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // --------------------------
  // Render
  // --------------------------
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-5">
      <div className="w-full max-w-3xl bg-white p-6 rounded-md shadow-md">
        {/* Tab Navigation */}
        <div className="flex border-b mb-4 text-sm">
          <button
            onClick={() => setActiveTab('contract')}
            className={`px-4 py-2 font-semibold ${
              activeTab === 'contract'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500'
            }`}
          >
            Contract Info
          </button>
          <button
            onClick={() => setActiveTab('contact')}
            className={`ml-4 px-4 py-2 font-semibold ${
              activeTab === 'contact'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500'
            }`}
          >
            Contact Info
          </button>
          <button
            onClick={() => setActiveTab('building')}
            className={`ml-4 px-4 py-2 font-semibold ${
              activeTab === 'building'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500'
            }`}
          >
            Building /System Info
          </button>
        </div>

        {/* CONTRACT TAB */}
        {activeTab === 'contract' && (
          <form onSubmit={handleNewContract} className="space-y-4">
            {/* 1) Auto Renew (Checkbox) */}
            <div className="inline-flex items-center gap-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="auto_renew"
                  checked={parseInt(newContractData?.auto_renew) === 1}
                  onChange={handleAutoRenewCheckbox}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Auto Renew? (default Yes)
                </span>
              </label>
             
             <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="takeover"
                  checked={parseInt(newContractData?.takeover) === 1}
                  onChange={handleAutoRenewCheckbox}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Take-Over
                </span>
              </label>
            </div>

            {/* 2) Expiration Date => only if auto_renew = 0 */}
            {newContractData?.auto_renew === '0' && (
              <div>
                <label htmlFor="exp_date" className="block text-sm font-medium text-gray-700">
                  Expiration Date
                </label>
                <input
                  type="date"
                  name="exp_date"
                  id="exp_date"
                  value={newContractData.exp_date}
                  onChange={handleNewInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            )}

            {/* 3) Select a Company => customer_id */}
            <div>
              <SelectCustomers
                label="Select a Company"
                items={companies}
                selectedItem={selectedCompany}
                setSelectedItem={handleCompanySelect}
                apiUrl={`${apiUrl}/customer_crud.php`}
              />
            </div>

            {/* 4) Contract Address + City + State + Zip */}
            <div>
              <label
                htmlFor="contract_address"
                className="block text-sm font-medium text-gray-700"
              >
                Contract Address <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                name="contract_address"
                id="contract_address"
                value={newContractData?.contract_address || ''}
                onChange={handleNewInputChange}
                maxLength="250"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="contract_city" className="block text-sm font-medium text-gray-700">
                  City <span className='text-red-500'>*</span>
                </label>
                <input
                  type="text"
                  name="contract_city"
                  id="contract_city"
                  value={newContractData?.contract_city || ''}
                  onChange={handleNewInputChange}
                  maxLength="200"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  required
                />
              </div>

              <div className="w-20">
                <label htmlFor="contract_state" className="block text-sm font-medium text-gray-700">
                  State <span className='text-red-500'>*</span>
                </label>
                <input
                  type="text"
                  name="contract_state"
                  id="contract_state"
                  value={newContractData?.contract_state || '' }
                  onChange={handleNewInputChange}
                  maxLength="2"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  required
                />
              </div>

              <div className="w-28">
                <label htmlFor="contract_zip" className="block text-sm font-medium text-gray-700">
                  Zip
                </label>
                <input
                  type="number"
                  name="contract_zip"
                  id="contract_zip"
                  value={newContractData?.contract_zip || '' }
                  onChange={handleNewInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
            </div>

            {/* 5) Account Number & Password side by side */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="account_number" className="block text-sm font-medium text-gray-700">
                  Account Number
                </label>
                <input
                  type="text"
                  name="account_number"
                  id="account_number"
                  value={newContractData?.account_number || '' }
                  onChange={handleNewInputChange}
                  maxLength="50"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password <span className='text-red-500'>*</span>
                </label>
                <input
                  type="text"
                  name="password"
                  id="password"
                  value={newContractData?.password || '' }
                  onChange={handleNewInputChange}
                  maxLength="100"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
            </div>

            {/* 6) Date Signed, Activation Date, Frequency side by side */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="date_signed" className="block text-sm font-medium text-gray-700">
                  Date Signed  <span className='text-red-500'>*</span>
                </label>
                <input
                  type="date"
                  name="date_signed"
                  id="date_signed"
                  value={newContractData?.date_signed || ''}
                  onChange={handleNewInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  required
                />
              </div>

              <div className="flex-1">
                <label htmlFor="activation_date" className="block text-sm font-medium text-gray-700">
                  Activation Date
                </label>
                <input
                  type="date"
                  name="activation_date"
                  id="activation_date"
                  value={newContractData?.activation_date || ''}
                  onChange={handleNewInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex-1">
                <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
                  Frequency <span className='text-red-500'>*</span>
                </label>
                <select
                  name="frequency"
                  id="frequency"
                  value={newContractData?.frequency || ''}
                  onChange={handleNewInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm sm:text-sm"
                  required
                >
                  <option value="12">Annually</option>
                  <option value="6">Semi-Annual</option>
                  <option value="3">Quarterly</option>
                  <option value="1">Monthly</option>
                </select>
              </div>
              </div>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="contract_price" className="block text-sm font-medium text-gray-700">
                  Contract Price <span className='text-red-500'>*</span>
                </label>
                <input
                  type="number"
                  name="contract_price"
                  id="contract_price"
                  value={newContractData?.contract_price || '' }
                  onChange={handleNewInputChange}
                  step="0.01"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  required
                />
              </div>

              {/* Tech */}
              <div className="flex-1">
                <label htmlFor="sold_by" className="block text-sm font-medium text-gray-700">
                  Sold By <span className='text-red-500'>*</span>
                </label>
                <select
                  id="employeeSelect"
                  name="sold_by"
                  value={newContractData?.sold_by || ''}
                  onChange={handleNewInputChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm">
                  <option value="">---</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={`${employee.first_name} ${employee.last_name}`}>
                      {employee.first_name} {employee.last_name}
                    </option>
                  ))}
                </select>
              </div>
              </div>

            <div>
              <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <textarea
                name="notes"
                id="notes"
                value={newContractData?.notes || ''}
                onChange={handleNewInputChange}
                maxLength="65535"
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
              />
            </div>

            <div className="mt-6 flex justify-end space-x-2">
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none"
                onClick={() => navigate('/cot_billing')}
              >
                Close
              </button>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none"
              >
                Save
              </button>
            </div>
          </form>
        )}

        {/* CONTACT TAB */}
        {activeTab === 'contact' && (
          <form onSubmit={handleContactSave} className="space-y-8">
 
            {/* Primary Contact 1 */}
            <div>
              <label className="block text-md font-semibold text-gray-700 mb-2">
                Primary Contact 
              </label>
              <div className="flex space-x-4">
                {/* Contact 1 Name */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="contact_name_p"
                    value={contactData?.contact_name_p}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                {/* Contact 1 Phone */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="contact_phone_p"
                    value={contactData.contact_phone_p}
                    onChange={handleContactChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                {/* Contact 1 Email */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="contact_email_p"
                    value={contactData.contact_email_p}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {/* Primary Contact 2 */}
            <div>
              <label className="block text-md font-semibold text-gray-700 mb-2">
                2nd Contact 
              </label>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="contact_name_s"
                    value={contactData.contact_name_s}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="contact_phone_s"
                    value={contactData.contact_phone_s}
                    onChange={handleContactChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="contact_email_s"
                    value={contactData.contact_email_s}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {/* Primary Contact 3 */}
            <div>
              <label className="block text-md font-semibold text-gray-700 mb-2">
                3rd Contact
              </label>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="contact_name_t"
                    value={contactData.contact_name_t}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="contact_phone_t"
                    value={contactData.contact_phone_t}
                    onChange={handleContactChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="contact_email_t"
                    value={contactData.contact_email_t}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {/* Primary Contact 4 */}
            <div>
              <label className="block text-md font-semibold text-gray-700 mb-2">
                4th Contact
              </label>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="contact_name_f"
                    value={contactData.contact_name_f}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <input
                    type="number"
                    name="contact_phone_f"
                    value={contactData.contact_phone_f}
                    onChange={handleContactChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>

                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="contact_email_f"
                    value={contactData.contact_email_f}
                    onChange={handleContactChange}
                    maxLength="250"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="mt-6 flex justify-end space-x-2">
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none"
                onClick={() => navigate('/cot_billing')}
              >
                Close
              </button>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none"
              >
                Save Contact
              </button>
            </div>
          </form>
        )}

        {/* BUILDING TAB */}
        {activeTab === 'building' && (
          <form onSubmit={handleBuildingSave} className="space-y-4">
            {/* TOP CHECKBOXES */}
            <div className="flex space-x-6">
              {/* fa_14_submitted */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="fa_14_submitted"
                  checked={buildingData.fa_14_submitted === '1'}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setBuildingData((prev) => ({
                      ...prev,
                      fa_14_submitted: checked ? '1' : '0',
                    }));
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label className="ml-2 text-sm font-medium text-gray-700">
                  FA 14 Submitted
                </label>
              </div>

              {/* base_building_loa */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="base_building_loa"
                  checked={buildingData.base_building_loa === '1'}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setBuildingData((prev) => ({
                      ...prev,
                      base_building_loa: checked ? '1' : '0',
                    }));
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label className="ml-2 text-sm font-medium text-gray-700">
                  Base Building LOA
                </label>
              </div>
            </div>

            {/* Row 1: BIN_number, building_classification, bdoc */}
            <div className="flex space-x-4">
              {/* BIN_number */}
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  BIN Number
                </label>
                <input
                  type="number"
                  name="BIN_number"
                  value={buildingData.BIN_number}
                  onChange={handleBuildingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>

              {/* Building Classification */}
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Building Classification
                </label>
                <input
                  type="text"
                  name="building_classification"
                  value={buildingData.building_classification}
                  onChange={handleBuildingChange}
                  maxLength="200"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>

              {/* bdoc */}
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  bdoc
                </label>
                <input
                  type="text"
                  name="bdoc"
                  value={buildingData.bdoc}
                  onChange={handleBuildingChange}
                  maxLength="100"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
            </div>

            {/* Row 2: date_loa, fdny_inspection_date */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Date LOA
                </label>
                <input
                  type="date"
                  name="date_loa"
                  value={buildingData.date_loa}
                  onChange={handleBuildingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  FDNY Inspection Date
                </label>
                <input
                  type="date"
                  name="fdny_inspection_date"
                  value={buildingData.fdny_inspection_date}
                  onChange={handleBuildingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
            </div>

            {/* Row 3: height, floors, floor_area */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Height
                </label>
                <input
                  type="number"
                  name="height"
                  value={buildingData.height}
                  onChange={handleBuildingChange}
                  step="0.01"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Floors
                </label>
                <input
                  type="number"
                  name="floors"
                  value={buildingData.floors}
                  onChange={handleBuildingChange}
                  step="1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Floor Area
                </label>
                <input
                  type="number"
                  name="floor_area"
                  value={buildingData.floor_area}
                  onChange={handleBuildingChange}
                  step="0.01"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
            </div>

            <div className='pt-4'><HeadingCp label="System" /></div>
            {/* Row 3: height, floors, floor_area */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Manufacture
                </label>
                <input
                  type="text"
                  name="system_manufacture"
                  value={buildingData.system_manufacture}
                  onChange={handleBuildingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  System Type
                </label>
                <input
                  type="text"
                  name="system_type"
                  value={buildingData.system_type}
                  onChange={handleBuildingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Panel Location
                </label>
                <input
                  type="text"
                  name="system_panel_location"
                  value={buildingData.system_panel_location}
                  onChange={handleBuildingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-2 py-4 flex justify-end space-x-2">
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none"
                onClick={() => navigate('/cot_billing')}
              >
                Close
              </button>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none"
              >
                Save Building
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default NewCotContract;

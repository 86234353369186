import React from 'react';
import { format, parseISO } from 'date-fns';

const DateFormat = ({ date }) => {
  // if(!date)
  // {
  //   return null
  // }
  // const formattedDate = format(parseISO(date), 'MMMM dd, yyyy');
  // return <span>{formattedDate}</span>;
      // console.log('DateFormat received:', date);
    if (!date) {
      return <span>No Date</span>;
    }
    const parsedDate = new Date(date);
    if (Number.isNaN(parsedDate.getTime())) {
      return <span>/</span>;
    }
    // return <span>{format(parsedDate, 'MM/dd/yyyy')}</span>;
    return format(parseISO(date), 'MMMM dd, yyyy');
};

export default DateFormat;

import { FaceSmileIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

const REACTIONS = [
  { type: 'like', label: 'Like', emoji: '❤️' },
  { type: 'thumbsUp', label: 'Thumbs Up', emoji: '👍' },
  { type: 'smile', label: 'Smile', emoji: '😊' },
  { type: 'mad', label: 'Mad', emoji: '😡' },
];

/**
 * comment: contains .reactions object or none
 * handleReaction: (postId, reactionType) => void
 */
const ReactionPost = ({ comment, handleReaction }) => {
  const reactionTotals = comment.reactions || {};
  const totalReactions = Object.values(reactionTotals).reduce((acc, val) => acc + val, 0);
  const allZero = totalReactions === 0;

  // Local state to toggle the inline emoji list
  const [showInline, setShowInline] = useState(false);

  // If all are zero, we show the "Reaction" button
  // Clicking it toggles an inline list of emojis to the right
  if (allZero && !showInline) {
    return (
      <div className="px-4 py-2">
        <button
          type="button"
          onClick={() => setShowInline(true)}
          className="inline-flex items-center rounded px-1 py-1 text-sm 
                     text-gray-700 hover:bg-gray-200 focus:outline-none"
        >
          <FaceSmileIcon className="h-5 w-5" />
        </button>
      </div>
    );
  }

  // If allZero but user has toggled open OR if any reaction > 0
  // We show a horizontal list of emojis to the right
  //   with transition or simple inline display
  return (
    <div className="relative px-4 py-2">
      {/* Reaction Button (only for the allZero case if user wants to close it) */}
      {allZero && showInline && (
        <button
          type="button"
          onClick={() => setShowInline(false)}
          className="inline-flex items-center rounded bg-gray-50 px-3 py-1 text-sm 
                     text-gray-400 hover:bg-gray-200 mr-2"
        >
          Hide
        </button>
      )}

      <div className="inline-flex items-center space-x-3">
        {REACTIONS.map((r) => {
          const count = reactionTotals[r.type] || 0;
          const hasCount = count > 0;

          return (
            <button
              key={r.type}
              onClick={() => handleReaction(comment.id, r.type)}
              className={`
                flex items-center space-x-1 text-sm 
                ${hasCount 
                  ? 'text-blue-600 hover:text-blue-800' 
                  : 'text-gray-400 hover:text-gray-500'}
              `}
            >
              <span>{r.emoji}</span>
              {hasCount && <span>{count}</span>}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ReactionPost;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import SubMenu from '../components/SubMenu';
import DateFormat from '../components/DateFormat';
import SearchBarCP from '../components/SearchBarCP';
import Modal from '../components/Modal';
import FileLocation from '../components/FileLocation';
import ModalWindow from '../components/ModalWindow';
import ModalNotesCp from '../components/ModalNotesCp';
import { useUserData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';
import DeliveryTrackingModal from '../components/DeliveryTrackingModal';
import CurrencyFormat from '../components/CurrencyFormat';
import Badge from '../components/Badge';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DeliveryTrackingPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/wip_crud.php`;

  const [items, setItems] = useState([]);      // all deliveries
  const [filterItems, setFilterItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // For sub-menu actions
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectDeliveryId, setSelectDeliveryId] = useState(null);
  const [file, setFile] = useState(null);

  // For notes
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const userData = useUserData();
  const navigate = useNavigate();

  // NEW: For opening the DeliveryTrackingModal
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null); // if editing an existing item

  // ---------------------------------------------
  // Fetch Delivery Items
  // ---------------------------------------------
  useEffect(() => {
    setLoading(true);
    const fetchDeliveryData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/wip_crud.php?getDelivery=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const data = Array.isArray(response.data) ? response.data : [];
        setItems(data);
        setFilterItems(data);
        if (data.length === 0) {
          toast.error("No Delivery found!", { position: "top-center" });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchDeliveryData();
  }, [apiUrl, navigate]);

  // ---------------------------------------------
  // Searching
  // ---------------------------------------------
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (!value) {
      setFilterItems(items);
    } else {
      const filtered = items.filter(obj =>
        (obj.job_number && obj.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (obj.job_site && obj.job_site.toLowerCase().includes(value.toLowerCase())) ||
        (obj.notes && obj.notes.toLowerCase().includes(value.toLowerCase()))
      );
      setFilterItems(filtered);
    }
  };

  const handleSearchClick = async () => {
    if (!searchTerm) {
      toast.info("Empty search term", { position: "top-center" });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/wip_crud.php`, {
        status: 'searchDelivery',
        data: { search: searchTerm }
      }, { headers: { 'Content-Type': 'application/json' } });
      if (Array.isArray(response.data)) {
        setItems(response.data);
        setFilterItems(response.data);
      } else {
        toast.error(`No results for ${searchTerm}`, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error searching deliveries', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------
  // Sub-menu actions
  // ---------------------------------------------
  const completeStatus = async (id, status) => {
    setLoading(true);
    const bodyData = {
      status: 'update_status_delivery_receipt',
      data: { dt_id: id, dt_status: status }
    };
    try {
      const response = await axios.post(api, bodyData, { headers: { 'Content-Type': 'application/json' } });
      if (response.data === 'success') {
        setFilterItems(prev => prev.map(item => {
          if (item.id === id) {
            return { ...item, status };
          }
          return item;
        }));
        toast.success("Status updated!", { position: 'top-center' });
      } else {
        toast.error("Error updating status", { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating status', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (dtId) => {
    try {
      const response = await axios.post(api, {
        status: 'delete_delivery_receipt',
        data: { dt_id: dtId }
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data === 'success') {
        toast.success("Receipt deleted!", { position: 'top-center' });
        setFilterItems(prev => prev.filter(item => item.id !== dtId));
        setItems(prev => prev.filter(item => item.id !== dtId));
      } else {
        toast.error("Failed to delete!", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("Error deleting receipt!", { position: 'top-center' });
    }
  };

  // Sub-menu generation
  const getLinksForJob = (object) => [
    { 
      name: 'Add Notes', 
      click: () => setNotesIdOpen(object.id), 
      key: `notes-${object.id}` 
    },
    {
      name: object.status === 'Complete' ? 'Status In Progress' : 'Status Complete',
      click: () => {
        const newStat = (object.status === 'Complete') ? 'In Progress' : 'Complete';
        completeStatus(object.id, newStat);
      },
      key: `complete-${object.id}`
    },
    {
      name: object.file ? 'Update Delivery Receipt' : 'Upload Delivery Receipt',
      click: () => {
        setSelectDeliveryId(object.id);
        setIsModalFileOpen(true);
      },
      key: `upload-file-${object.id}`
    },
    {
      name: 'Delete Receipt',
      click: () => handleDelete(object.id),
      key: `delete-${object.id}`
    },
    {
      name: 'Edit PO Record',
      click: () => openModal(object),
      key: `edit-po-${object.id}`
    }
  ];

  // -------------- File Upload --------------
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }
    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB
    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. PDF only", { position: 'top-center' });
      setFile(null);
      return;
    }
    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }
    setFile(selectedFile);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("No file selected", { position: 'top-center' });
      return;
    }
    try {
      const formData = new FormData();
      formData.append('status', 'submit_delivery_receipt');
      formData.append('id', selectDeliveryId);
      formData.append('file', file);

      const response = await axios.post(`${apiUrl}/delivery_receipt.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.success("Saved!", { position: 'top-center' });
        // update local state
        setFilterItems(prev =>
          prev.map(item => {
            if (item.id === parseInt(response.data.id)) {
              return {
                ...item,
                file: response.data.delivery_receipt.file,
                status: response.data.delivery_receipt.status
              };
            }
            return item;
          })
        );
      } else {
        toast.error(response.data.error || "File upload error", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("Error uploading file", { position: 'top-center' });
    } finally {
      setFile(null);
      setIsModalFileOpen(false);
    }
  };

  // -------------- Notes --------------
  const handleChangeNotes = (event, id, user) => {
    // handle logic for notes if needed
  };

  // -------------- Show/Hide the custom DeliveryTrackingModal --------------
  const openModal = (data = null) => {
    setEditData(data);  // if null, it means new
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setEditData(null);
  };

  // -------------- handleModalSubmit from the DeliveryTrackingModal --------------
  const handleModalSubmit = async (formData) => {
    try {
      // If there's an 'id', we might do an update, else create
      if (!formData.data.job_number) {
        toast.error("Job number required", { position: 'top-center' });
        return;
      }
      // Example: pass to wip_crud
      const response = await axios.post(api, formData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Purchase order data saved!", { position: 'top-center' });
        // If it's a new item, push to local state, else update an existing one
        if (!formData.data.id) {
          // new
          setFilterItems(prev => [response.data.success, ...prev]);
          setItems(prev => [response.data.success, ...prev]);
        } else {
          // updating existing
          setFilterItems(prev =>
            prev.map(item =>
              item.id === formData.data.id
                ? { ...item, ...response.data.success }
                : item
            )
          );
          setItems(prev =>
            prev.map(item =>
              item.id === formData.data.id
                ? { ...item, ...response.data.success }
                : item
            )
          );
        }
        closeModal();
      } else {
        toast.error(response.data.error || "Error saving data", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("Error: " + error, { position: 'top-center' });
    }
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 py-4">
            Delivery Tracking | Purchase Order
          </h1>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">

          <div className="sm:flex sm:items-center py-6">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              {/* Button that opens new record in the modal */}
              <button
                type="button"
                onClick={() => openModal(null)} // no data => new record
                className="mr-2 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Add Purchase Order
              </button>

              <div className="flex-grow">
                <SearchBarCP
                  search={searchTerm}
                  handleChange={handleSearchChange}
                  onClick={handleSearchClick}
                />
              </div>
            </div>
          </div>

          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <LoadingSpinner>Loading...</LoadingSpinner>
            ) : (
              <div className="shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead className="bg-white">
                    <tr>
                      <th className="border-b border-gray-300 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                        ID/Edit</th>
                      <th className="border-b border-gray-300 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                        Job Number | Job Site</th>
                      <th className="border-b border-gray-300 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                        File</th>
                      <th className="border-b border-gray-300 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                        Vendor | SO#</th>
                      <th className="border-b border-gray-300 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                        Invoice# | Proposal</th>
                      <th className="border-b border-gray-300 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                        E-PO</th>
                      <th className="border-b border-gray-300 py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                        Notes</th>                        
                    </tr>
                  </thead>
                  <tbody>
                    {filterItems.map((obj, idx) => (
                      <tr key={obj.id} className={(idx % 2 === 0 ? 'bg-white' : 'bg-gray-100') + ' divide-x'}>
                        <td className="py-4 px-3 text-sm font-medium text-gray-900 border-b border-gray-200">
                          <SubMenu label={obj.id} idItem={obj.id} links={getLinksForJob(obj)} />
                          <div className='text-xs'>{obj.employee_name}</div>
                          <Badge feed={obj.status} color={obj.status === 'Pending' ? 'yellow' : 'green'}/>
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 border-b border-gray-200">
                          {obj.job_number}
                          <br />
                          <span className="text-gray-500">{obj.job_site}</span>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 border-b border-gray-200">
                          <FileLocation expFile={obj.file} where="delivery_receipt" />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200">
                          <div className='font-bold'>{obj.vendor}</div>
                          <div className='text-xs'><CurrencyFormat value={obj.vendor_price}/></div>
                          <div className='font-bold'>{obj.so_number}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200">
                          <div className='text-xs'>{obj.invoice_number}</div>
                          <div className='text-xs'>{obj.proposal_number}</div>
                          <div className='text-xs'><DateFormat date={obj.date_proposal}/></div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200">
                          <div className='font-bold'>{obj.empire_po}</div>
                          <div className='text-xs'><DateFormat date={obj.po_date}/></div>
                          <div className='text-xs'><CurrencyFormat value={obj.empire_price} /></div>
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 border-b border-gray-200 sm:table-cell">
                          {obj.notes}
                          {notesIdOpen === obj.id && (
                            <ModalWindow
                              job={
                                <ModalNotesCp
                                  onChange={(e) => handleChangeNotes(e, obj.id, userData?.users)}
                                  api={api}
                                  inside="notes"
                                  val={obj}
                                  bodyData={{ /* your notes body data here */ }}
                                  redirectPath="/delivery_tracking"
                                  closeNotes={() => setNotesIdOpen(null)}
                                />
                              }
                              closeNotes={() => setNotesIdOpen(null)}
                            />
                          )}
                        </td>                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* File upload modal */}
      <Modal isOpen={isModalFileOpen} onClose={() => setIsModalFileOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Upload Delivery Receipt</h2>
        <form onSubmit={handleUploadFile}>
          <div className="mt-4">
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
              required
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
            />
          </div>
          <div className="mt-6 text-right">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent
                         bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm
                         hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500
                         focus:ring-offset-2"
            >
              Upload
            </button>
          </div>
        </form>
      </Modal>

      {/* Our new DeliveryTrackingModal for new or edit */}
      <DeliveryTrackingModal
        isOpen={modalOpen}
        onClose={closeModal}
        onSubmit={handleModalSubmit}   // We'll handle submission
        initialData={editData || {}}   // either empty for new or existing row
      />
    </div>
  );
};

export default DeliveryTrackingPage;

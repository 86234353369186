// Tooltip.jsx
import React from 'react';

function Tooltip({ children, visible, x, y }) {
  if (!visible) return null;

  return (
    <div
      style={{
        position: 'absolute',
        left: x + 10,   // small offset so it's not right under the cursor
        top: y + 10,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        padding: '4px 6px',
        borderRadius: '4px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
        zIndex: 9999
      }}
    >
      {children}
    </div>
  );
}

export default Tooltip;

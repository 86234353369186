import React, { useState, useEffect, Fragment } from 'react';
// import WipComments from '../components/WipComments';  // Update the import path if necessary

import axios from 'axios';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { Dialog, DialogPanel, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Bars3Icon, CalendarDaysIcon, CreditCardIcon, EllipsisVerticalIcon, FaceFrownIcon, FaceSmileIcon, FireIcon, HandThumbUpIcon, HeartIcon, PaperClipIcon, UserCircleIcon, XCircleIcon, XMarkIcon as XMarkIconMini } from '@heroicons/react/20/solid';
import { BellIcon, XMarkIcon as XMarkIconOutline } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyFormat from '../components/CurrencyFormat';
import AvatarLocation from '../components/AvatarLocation';
import DateFormat from '../components/DateFormat';
import WipComments from '../components/ModalWipNotesSection';
import CreditHoldBannerCp from '../components/CreditHoldBannerCp';
import FileLocationCp from '../components/FileLocationCp';
import SignedProposalCP from '../components/SignedProposalCP';
import FileLocation from '../components/FileLocation';
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const WipDetailPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { key } = useParams();
  const [wip, setWip] = useState({
    id: '',
    job_number: '',
    job_site: '',
    customer_id: '',
    job_description: '',
    type: '',
    f_p_type: '',
    prev_wage: '',
    price: '',
    invoiced: '',
    paid: '',
    void: '',
    pm: '',
    tax_status: '',
    date: '',
    complete: 0,
    acc_approval: '',
    eng_approval: '',
    file:'',
    estimate_hours:''
  });
  const [pm, setPm] = useState();
  const [notes, setNotes] = useState([]);
  const [accountVendor, setAccountVendor] = useState([]);
  const [engineering, setEngineering] = useState([]);
  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const [creditHold, setCreditHold] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [deliveryTracking, setDeliveryTracking] = useState([]);
  const [employeeDepartment, setEmployeeDepartment] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [totalPriceProposal, setTotalPriceProposal] = useState(0);
  const [projects, setProjects] = useState([]);
  const [totalProjectPrice, setTotalProjectPrice] = useState(0);
  const [hourData, setHourData] = useState([]);
  const [openTechId, setOpenTechId] = useState(null);  
  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit_notes',
    data: {}
  });
  const api = `${apiUrl}/wip_crud.php`;

 // For TABS: Overview, Invoice, Delivery
  const [activeTab, setActiveTab] = useState('overview');

  const handleNoteChangeParent = (id, newNote) => {
    setNotes((prevNotes) => (Array.isArray(prevNotes) ? [...prevNotes, newNote] : [newNote]));
  };

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axios.get(`${apiUrl}/wip_crud.php`, {
          params: {
            getWipDetailStat: key
          }
        });
        const data = response.data;
        if (data.error) {
          toast.error(data.error, {
            position: 'top-center'
          });
          navigate('/wip_list/aj')
        }
        setWip(data.wip_detail);
        setPm(data.employees);
        setNotes(data.notes ?? []);
        setInvoiceHistory(data.invoice_history ?? []);
        setCreditHold(data.credit_hold ?? []);
        setAccountVendor(data.account_vendor ?? []);
        setDeliveryTracking(data.delivery_tracking ?? []);
        setEngineering(data.engineering_data ?? []);
        setEmployeeDepartment(data.department ?? []);
        setProposals(data.proposal_stats.stats ?? []);
        setTotalPriceProposal(data.proposal_stats.total ?? 0);
        setProjects(data.project_stats.stats ?? []);
        setTotalProjectPrice(data.project_stats.total ?? 0);
        setHourData(data.hours_per_tech ?? []);        
      } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
          console.log('403 Forbidden');
        }
      }
    };

    fetchCustomer();
  }, [key, apiUrl, navigate]);

  return (
    <>
    {creditHold ? (
      <>
       <CreditHoldBannerCp children={(
          <>
          Amount owed: <CurrencyFormat value={creditHold.amount}/>
          | Comment : {creditHold.notes || ''}
          </>
       )} href={'/credit_hold'}/>
      </>
    ) : ('')}

    {wip?.customer_hold  ? (
      <>
       <CreditHoldBannerCp children={(
          <>
          Company on hold 
          </>
       )} href={'/customers'}/>
      </>
    ) : ('')}
      <main>
        <div className="mx-auto max-w-[1250px] px-4 py-16 sm:px-6 lg:px-8">
          <a href="/wip_list/aj" className="text-sm font-semibold leading-6 text-blue-700">
            <span aria-hidden="true">&larr;</span> Back to Wip
          </a>
            {employeeDepartment === 'Sales' || employeeDepartment === 'Admin' || employeeDepartment === 'Install' ? (
              <NavLink to={`/wip/edit/${wip.id}`}>
                <span className="text-sm font-semibold leading-6 text-red-600 px-3">
                | Edit
                </span>
              </NavLink>
            ) : ('')}
          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div className="lg:col-start-3 lg:row-end-1">
              {/* <h2 className="sr-only">Summary</h2> */}
              <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-300">
                <dl className="flex flex-wrap">
                  <div className="flex-auto pl-6 pt-6">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">Price</dt>
                    <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                      {<CurrencyFormat value={wip?.price} />}
                       {wip?.invoiced < 100 && (
                          <div className='text-red-700 text-xs'>&#8723;<CurrencyFormat value={wip.price - (wip?.invoiced * wip.price /100)}/></div>
                        )}
                    </dd>
                  </div>
                  <div className="flex-none self-end px-6 pt-4">
                    <dt className="sr-only">Status</dt>
                    <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                      {
                        wip?.tax_status === "tax" ?  'Taxable' : '' ||
                        wip?.tax_status === "CI"  ?  'Capital Improvement' : '' ||
                        wip?.tax_status === "CE"  ?  'Contractor Exempt' : ''||
                        wip?.tax_status === "EO"  ?  'Exempt Organization' : '' ||
                        wip?.tax_status === "OE"  ?  'Other Exempt' : ''
                      }
                    </dd>
                  </div>
                  <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt className="flex-none">
                      <span className="sr-only">Project Manager</span>
                      {<AvatarLocation className="h-8 w-8 rounded-full" avatar={pm?.avatar} />}
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-900">
                      {pm?.first_name} {pm?.last_name} (Project Manager)
                    </dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Added</span>
                      <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      <time dateTime="2023-01-31"><DateFormat date={wip?.date} /></time>
                    </dd>
                  </div>
                </dl>
                <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    View Proposal <span aria-hidden="true">&rarr;</span>
                  </span>
                  {/* <SignedProposalCP expFile={`${wip.file}`} /> */}
                  <FileLocation expFile={wip?.file} where={'signed_proposal'}/>
                </div>
              </div>
            </div>
            <div className="-mx-4 px-4 py-8 shadow-sm ring-2 ring-gray-400/10 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
              <h2 className="text-base font-semibold leading-6 text-gray-900">
                <p className='text-sm text-gray-500'>ProjectID: {wip?.id}</p>
                Proposal # {wip?.job_number}
              </h2>
              <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4"></div>
                <div className="mt-2 sm:mt-0 sm:pl-4"></div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">
                    {wip?.job_site}
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {wip?.customer_name}
                    </span>
                    <br />
                    {wip?.job_description}
                    <br />
                    {wip?.type}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {wip?.f_p_type === 'SO' ? 'Supply Only' : 'Supply & Install'}
                    </span>
                    <br />
                      Paid: {wip?.paid || 0}%
                    <br />
                      Invoiced: {wip?.invoiced || 0}%
                    <br />
                      {wip?.acc_approval === 1 ? 'Vendor payment required' : 'No vendor required'}
                      <span className={`${wip?.acc_approval === 1 && wip?.acc_status === "complete" ? 'text-green-500' : 'text-red-500'}`}>
                        {wip?.acc_approval === 1 && wip?.acc_status === "complete" ? ': Paid 100%' : ''}
                      </span>
                    <br />
                      <span className={`${engineering.id ? 'text-green-600' : 'text-red-500'}`}>
                        {engineering.id ? 'Engineering contractor required' : 'No engineering contractor required'}
                      </span>
                      <span className={`${wip?.eng_approval === 1 && wip?.eng_status === 'complete' ? 'text-green-500' : 'text-red-500'}`}>
                        {wip?.eng_approval === 1 && wip?.eng_status === 'complete' ? ': Done' : ''}
                      </span>
                  </dd>
                  <dd className="mt-2 text-gray-500">
                      {/* <span className={`${wip?.acc_approval === 1 && wip?.acc_status === "complete" ? '' : ''}`}>
                        {wip?.eng_approval === 1 ? 'Engineering Pending' : 'No engineering required'}
                      </span> */}
                      <span className={`${wip?.estimate_hours >= 1 ? 'text-green-700' : 'text-red-500'}`}>
                        {wip?.estimate_hours >= 1 ? 'Estimated: ' + wip?.estimate_hours +'Hrs' : ''}
                      </span>
                  </dd>
                </div>
              </dl>

                {accountVendor.id ? (
                  <>
                    <div
                      className={`flex w-full items-center justify-between mt-10 border-t border-b pt-2 bg-[#fffbeb] p-4 ${
                        accountVendor.status === 'void' ? 'line-through text-red-500' : ''
                      }`}
                    >
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="truncate text-sm font-medium text-gray-900">
                            <NavLink to={'/accounting'}>
                              Vendor: {accountVendor?.vendor_name}
                            </NavLink>
                          </h3>
                          <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            i: {accountVendor?.adv_inv_num}
                          </span>
                        </div>
                        <p className="mt-1 truncate text-sm text-gray-500">
                          {accountVendor.vendor_rate_paid && accountVendor.vendor_rate_paid}% Paid
                        </p>
                        <p>{accountVendor?.notes}</p>
                      </div>
                    </div>
                  </>
                ) : ('')}
                {engineering.id ? (
                  <>
                  <div className="flex w-full items-center justify-between mt-10 border-t border-b pt-2 bg-[#e8f1ff] p-4">
                  <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                  <div>
                    {/* Section title */}
                    <h2 className="text-sm font-semibold text-gray-700 mb-3">Engineering</h2>
                    <ul className="space-y-1 text-sm text-gray-900 list-inside">
                      <li className="flex items-center">
                        <span className="mr-1 text-gray-600">1LR from field:</span>
                        {engineering.line_riser_field ? (
                          <div className="inline-flex items-center text-green-600">
                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                            Yes
                          </div>
                        ) : (
                          <div className="inline-flex items-center text-red-500">
                            <XCircleIcon className="h-5 w-5 mr-1" />
                            No
                          </div>
                        )}
                      </li>
                      <li className="flex items-center">
                        <span className="mr-1 text-gray-600">1LR signed & sealed:</span>
                        {engineering.line_riser_signed_sealed ? (
                          <div className="inline-flex items-center text-green-600">
                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                            Yes
                          </div>
                        ) : (
                          <div className="inline-flex items-center text-red-500">
                            <XCircleIcon className="h-5 w-5 mr-1" />
                            No
                          </div>
                        )}
                      </li>
                      <li className="flex items-center">
                        <span className="mr-1 text-gray-600">A433 completed:</span>
                        {engineering.a433_complete ? (
                          <div className="inline-flex items-center text-green-600">
                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                            Yes
                          </div>
                        ) : (
                          <div className="inline-flex items-center text-red-500">
                            <XCircleIcon className="h-5 w-5 mr-1" />
                            No
                          </div>
                        )}
                      </li>
                      <li className="flex items-center">
                        <span className="mr-1 text-gray-600">A433 returned from electrician:</span>
                        {engineering.a433_returned_from_elec ? (
                          <div className="inline-flex items-center text-green-600">
                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                            Yes
                          </div>
                        ) : (
                          <div className="inline-flex items-center text-red-500">
                            <XCircleIcon className="h-5 w-5 mr-1" />
                            No
                          </div>
                        )}
                      </li>
                      <li className="flex items-center">
                        <span className="mr-1 text-gray-600">TB60 completed:</span>
                        {engineering.tb60_complete ? (
                          <div className="inline-flex items-center text-green-600">
                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                            Yes
                          </div>
                        ) : (
                          <div className="inline-flex items-center text-red-500">
                            <XCircleIcon className="h-5 w-5 mr-1" />
                            No
                          </div>
                        )}
                      </li>
                      <li className="flex items-center">
                        <span className="mr-1 text-gray-600">PAF received:</span>
                        {engineering.paf_received ? (
                          <div className="inline-flex items-center text-green-600">
                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                            Yes
                          </div>
                        ) : (
                          <div className="inline-flex items-center text-red-500">
                            <XCircleIcon className="h-5 w-5 mr-1" />
                            No
                          </div>
                        )}
                      </li>
                      <li className="flex items-center">
                        <span className="mr-1 text-gray-600">Paperwork sent to expeditor:</span>
                        {engineering.paperwork_sent_expeditor ? (
                          <div className="inline-flex items-center text-green-600">
                            <CheckCircleIcon className="h-5 w-5 mr-1" />
                            Yes
                          </div>
                        ) : (
                          <div className="inline-flex items-center text-red-500">
                            <XCircleIcon className="h-5 w-5 mr-1" />
                            No
                          </div>
                        )}
                      </li>
                        {engineering.b45_with_fd && (
                          <li className="flex items-center">
                            <span className="mr-1 text-gray-600">B45 with FD:</span>
                              <div className="inline-flex items-center text-green-600">
                                <DateFormat date={engineering.b45_with_fd} />
                              </div>
                          </li>
                        )}
                    </ul>
                  </div>
                  </div>
                  </div>
                  </div>
                  </>
                ) : ('')}
                
              {/* TABS BAR */}
              <div className="mt-10 border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  <button
                    onClick={() => setActiveTab('overview')}
                    className={classNames(
                      activeTab === 'overview'
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                    )}
                  >
                    Overview
                  </button>
                  <button
                    onClick={() => setActiveTab('invoice')}
                    className={classNames(
                      activeTab === 'invoice'
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                    )}
                  >
                    Invoiced History
                  </button>
                  <button
                    onClick={() => setActiveTab('delivery')}
                    className={classNames(
                      activeTab === 'delivery'
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                    )}
                  >
                    Delivery Receipt
                  </button>

                  <button  onClick={() => setActiveTab('hours')}
                    className={classNames(
                      activeTab === 'hours'
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                    )}
                  >
                    Hours Tracking
                  </button>
                </nav>
              </div>

              {/* TAB PANELS BELOW */}
              {activeTab === 'overview' && (
                <div className="mt-6 text-sm text-gray-600">
                  <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-2">
                    {/* column one */}
                    <div className="relative overflow-hidden rounded-lg bg-white pb-2 pt-5 shadow sm:px-6 sm:pt-6">
                      <dt>
                        <div className="absolute rounded-md bg-indigo-600 p-1">
                          <EnvelopeOpenIcon aria-hidden="true" className="size-6 text-white" />
                        </div>
                        <p className="ml-10 truncate text-sm font-medium text-gray-500 border-b-2 border-gray-200 mb-4">
                          Proposals Linked Sent
                        </p>
                      </dt>

                      <dd className="flex flex-col">
                        {/* The big number */}
                        <p className="text-xl font-semibold text-gray-900 bg-yellow-100 rounded px-1 mb-2">
                        Total: <CurrencyFormat value={totalPriceProposal}/>
                        </p>

                        {/* The list of proposals */}
                        <div className="text-sm ">
                        {proposals?.length > 0 ? (
                          <ul>
                            {proposals?.map((proposal, index) => (
                              <li key={proposal.id || index} className="border-b border-gray-300 mb-4">
                                <span className='font-extrabold text-black'>{proposal.customer_name}</span>
                                <span className='float-end text-xs text-blue-700'>{proposal.date}</span>
                                <div className='mb-2'>{proposal.description}</div>
                                <div className='text-green-700 font-bold text-xs'>
                                  {proposal.jobnum} | <CurrencyFormat value={proposal.price}/>
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-sm text-gray-500">No proposals linked</p>
                        )}
                        </div>
                      </dd>
                    </div>

                    {/* column two */}
                    <div className="relative overflow-hidden rounded-lg bg-white pb-2 pt-5 shadow sm:px-6 sm:pt-6">
                      <dt>
                        <div className="absolute rounded-md bg-green-600 p-1">
                          <CursorArrowRaysIcon aria-hidden="true" className="size-6 text-white" />
                        </div>
                        <p className="ml-10 truncate text-sm font-medium text-gray-500 border-b-2 border-gray-200 mb-4">
                          Proposals Linked Awarded 
                        </p>
                      </dt>

                      <dd className="flex flex-col">
                        {/* The big number */}
                        <p className="text-xl font-semibold text-gray-900 bg-yellow-100 rounded px-1 mb-2">
                        Total: <CurrencyFormat value={totalProjectPrice}/>
                        </p>

                        {/* The list of proposals */}
                        <div className="text-sm ">
                        {projects?.length > 0 ? (
                          <ul>
                            {projects?.map((project, index) => (
                              <li key={project.id || index} className="border-b border-gray-300 mb-4">
                                <span className='font-extrabold text-black'>{project.customer_name}</span>
                                <span className='float-end text-xs text-blue-700'>{project.date}</span>
                                <div className='mb-2'>{project.job_description}</div>
                                <div className='text-green-700 font-bold text-xs'>
                                  {project.job_number} | <CurrencyFormat value={project.price}/> | {project.pm && (<>PM: {project.pm}</>)}
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-sm text-gray-500">No proposals linked</p>
                        )}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
              )}

              {activeTab === 'invoice' && (
                <div className="mt-6">
                  {invoiceHistory.length > 0 ? (
                    <table className="w-full whitespace-nowrap text-left text-sm leading-6">
                      <colgroup>
                        <col className="w-full" />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead className="border-b border-gray-200 text-gray-900">
                        <tr>
                          <th scope="col" className="px-0 py-3 font-semibold">
                            Invoiced history
                          </th>
                          <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                            Invoiced Rate
                          </th>
                          <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                            Payment Rate
                          </th>
                          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                            WIP
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceHistory.map((item) => (
                          <tr key={item.id} className="border-b border-gray-200">
                            <td className="max-w-0 px-0 py-5 align-top">
                              <div className="truncate font-medium text-gray-900">
                                <DateFormat date={item.date} />
                              </div>
                              <div className="truncate text-gray-500">
                                by {item.by_user}
                              </div>
                            </td>
                            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                              {item.invoice_rate}%
                            </td>
                            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                              {item.paid_rate}%
                            </td>
                            <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                              <CurrencyFormat value={wip?.price * item?.invoice_rate / 100} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="p-4">No invoiced history</div>
                  )}
                </div>
              )}

              {activeTab === 'delivery' && (
                <div className="mt-6">
                  {deliveryTracking.length > 0 ? (
                    <table className="w-full text-left text-sm leading-6">
                      <colgroup>
                        <col className="w-full" />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead className="border-b border-gray-200 text-gray-900">
                        <tr>
                          <th scope="col" className="px-0 py-3 font-semibold">
                            Delivery Receipt Notes
                          </th>
                          <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                            File
                          </th>
                          <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                            Status
                          </th>
                          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                            By | Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryTracking.map((item) => (
                          <tr key={item.id} className="border-b border-gray-200">
                            <td className="max-w-0 px-0 py-5 align-top">
                              {item.notes}
                            </td>
                            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                              <FileLocation expFile={item?.file} where={'delivery_receipt'} />
                            </td>
                            <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                              {item.status}
                            </td>
                            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell flex-wrap">
                              <div className="truncate font-medium text-gray-900">
                                <DateFormat date={item.date} />
                              </div>
                              <div className="truncate text-gray-500">
                                by {item.employee_name}
                              </div>
                              <div className="truncate text-gray-500">
                                id: {item.id}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="p-4">No Delivery Receipt</div>
                  )}
                </div>
              )}

              {/* {activeTab === 'hours' && (
                <div className="p-4">pending</div>
              )} */}
              {activeTab === 'hours' && (
                <div className="p-4">
                  <table className="min-w-full border border-gray-300 bg-white">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-700">Tech ID</th>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-700">Tech Name</th>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-700">Reg Hrs</th>
                        <th className="px-3 py-2 text-left text-sm font-semibold text-gray-700">Over Hrs</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {hourData.map((tech) => {
                        const isOpen = openTechId === tech.tech_id;
                        return (
                          <React.Fragment key={tech.tech_id+tech.tech_name}>
                            {/* Top layer row */}
                              <tr onClick={() => setOpenTechId(isOpen ? null : tech.tech_id)}
                              className="cursor-pointer hover:bg-gray-50">
                              <td className="px-3 py-2 text-sm text-gray-800">{tech.tech_id}</td>
                              <td className="px-3 py-2 text-sm text-gray-800 font-semibold">
                                {tech.tech_name}
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-800">{tech.total_reg_hrs}</td>
                              <td className="px-3 py-2 text-sm text-gray-800">{tech.total_over_hrs}</td>
                            </tr>

                            {/* Second layer (details) */}
                            {isOpen && (
                              <tr>
                                {/* Use colSpan=4 to span across all table columns */}
                                <td colSpan={4} className="p-0">
                                  <div
                                    className="overflow-hidden transition-all duration-300"
                                    style={{ marginLeft: '30px' }}  // shift a bit to the right
                                  >
                                    <table className="min-w-full bg-gray-50 shadow-inner">
                                      <thead className="bg-white">
                                        <tr>
                                          <th className="px-2 py-1 text-left text-xs font-semibold text-gray-700">Date</th>
                                          <th className="px-2 py-1 text-left text-xs font-semibold text-gray-700">Reg Hrs</th>
                                          <th className="px-2 py-1 text-left text-xs font-semibold text-gray-700">Over Hrs</th>
                                          <th className="px-2 py-1 text-left text-xs font-semibold text-gray-700">Time In</th>
                                          <th className="px-2 py-1 text-left text-xs font-semibold text-gray-700">Time Out</th>
                                          <th className="px-2 py-1 text-left text-xs font-semibold text-gray-700">Notes</th>
                                          <th className="px-2 py-1 text-left text-xs font-semibold text-gray-700">Pay Period</th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200">
                                        {tech.records.map((record) => (
                                          <tr key={record.id}>
                                            <td className="px-2 py-1 text-sm text-gray-600">{record.date}</td>
                                            <td className="px-2 py-1 text-sm text-gray-600">{record.reg_hrs}</td>
                                            <td className="px-2 py-1 text-sm text-gray-600">{record.over_hrs}</td>
                                            <td className="px-2 py-1 text-sm text-gray-600">{record.time_in}</td>
                                            <td className="px-2 py-1 text-sm text-gray-600">{record.time_out}</td>
                                            <td className="px-2 py-1 text-sm text-gray-600">{record.notes}</td>
                                            <td className="px-2 py-1 text-sm text-gray-600">{record.pay_period}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

            </div>

            {/* RIGHT-SIDE Activity (Notes) */}
            <div className="lg:col-start-3">
              <h2 className="text-sm font-semibold leading-6 text-gray-900">Activity</h2>
              <WipComments
                api={api}
                inside={'notes'}
                notes={notes}
                bodyData={bodyDataNotes}
                val={''}
                id={wip?.id}
                handleNoteChangeParent={handleNoteChangeParent}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WipDetailPage;
